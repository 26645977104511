"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __awaiter = this && this.__awaiter || function (thisArg, _arguments, P, generator) {
  function adopt(value) {
    return value instanceof P ? value : new P(function (resolve) {
      resolve(value);
    });
  }

  return new (P || (P = Promise))(function (resolve, reject) {
    function fulfilled(value) {
      try {
        step(generator.next(value));
      } catch (e) {
        reject(e);
      }
    }

    function rejected(value) {
      try {
        step(generator["throw"](value));
      } catch (e) {
        reject(e);
      }
    }

    function step(result) {
      result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected);
    }

    step((generator = generator.apply(thisArg, _arguments || [])).next());
  });
};

var __generator = this && this.__generator || function (thisArg, body) {
  var _ = {
    label: 0,
    sent: function sent() {
      if (t[0] & 1) throw t[1];
      return t[1];
    },
    trys: [],
    ops: []
  },
      f,
      y,
      t,
      g;
  return g = {
    next: verb(0),
    "throw": verb(1),
    "return": verb(2)
  }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
    return this;
  }), g;

  function verb(n) {
    return function (v) {
      return step([n, v]);
    };
  }

  function step(op) {
    if (f) throw new TypeError("Generator is already executing.");

    while (_) {
      try {
        if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [op[0] & 2, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }
    }

    if (op[0] & 5) throw op[1];
    return {
      value: op[0] ? op[1] : void 0,
      done: true
    };
  }
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var utilities_1 = require("@dynamynd/utilities");

var authKey = 'auth';
var initialAuthData = {
  access_token: '',
  expires_at: ''
};

var AuthService =
/** @class */
function () {
  function AuthService() {
    this.credentials = __assign({}, initialAuthData);
  }

  AuthService.getInstance = function () {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }

    return AuthService.instance;
  };

  AuthService.prototype.validateAuth = function () {
    return __awaiter(this, void 0, void 0, function () {
      var credentials, hasCredentials;
      return __generator(this, function (_a) {
        switch (_a.label) {
          case 0:
            return [4
            /*yield*/
            , this.getStorageCredentials()];

          case 1:
            credentials = _a.sent();
            hasCredentials = this.hasCredentials(credentials);

            if (hasCredentials) {
              this.handleUserHasCredentials(credentials);
            }

            return [2
            /*return*/
            ];
        }
      });
    });
  };

  AuthService.prototype.handleUserHasCredentials = function (credentials) {
    this.credentials = __assign({}, credentials);
    var isTokenExpired = this.isTokenExpired();

    if (isTokenExpired) {
      this.revoke();
      return;
    }
  };

  AuthService.prototype.isAuthenticated = function () {
    var hasCredentials = this.hasCredentials(this.credentials);

    if (!hasCredentials) {
      return false;
    }

    var isTokenExpired = this.isTokenExpired();
    return !isTokenExpired;
  };

  AuthService.prototype.getStorageCredentials = function () {
    return __awaiter(this, void 0, void 0, function () {
      var _a, access_token, expires_at, _b, _c;

      return __generator(this, function (_d) {
        switch (_d.label) {
          case 0:
            _c = (_b = core_1.parseStorage).parseData;
            return [4
            /*yield*/
            , core_1.parseStorage.getData(authKey)];

          case 1:
            _a = _c.apply(_b, [_d.sent()]), access_token = _a.access_token, expires_at = _a.expires_at;
            return [2
            /*return*/
            , {
              access_token: access_token,
              expires_at: expires_at
            }];
        }
      });
    });
  };

  AuthService.prototype.setCredentials = function (_a) {
    var access_token = _a.access_token,
        expires_in = _a.expires_in;
    var authData = {
      access_token: access_token,
      expires_at: this.getExpirationTimeUnix(expires_in)
    };
    this.credentials = authData;
    core_1.parseStorage.setData(authKey, authData);
  };

  AuthService.prototype.revoke = function () {
    this.credentials = __assign({}, initialAuthData);
    core_1.parseStorage.removeData(authKey);
  };

  AuthService.prototype.hasCredentials = function (credentials) {
    return !!credentials.access_token && !!credentials.expires_at;
  };

  AuthService.prototype.getAccessToken = function () {
    var access_token = this.credentials.access_token;
    return access_token;
  };

  AuthService.prototype.getExpirationTimeUnix = function (expiresIn) {
    var expiresSec = parseInt(expiresIn, 10) * 60;
    return utilities_1.expirationTimeUnix(expiresSec).toString();
  };

  AuthService.prototype.isTokenExpired = function () {
    var expiresAt = this.credentials.expires_at;

    if (!expiresAt) {
      return true;
    }

    var currentTime = utilities_1.currentTimeUnix();
    var expire = parseInt(expiresAt, 10);
    return currentTime > expire;
  };

  return AuthService;
}();

exports.AuthService = AuthService;
exports.authService = AuthService.getInstance();