"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var core_1 = require("@dynamynd/core");

var modules_1 = require("@dynamynd/modules");

var responsive_1 = require("@dynamynd/responsive");

var routes_1 = require("@dynamynd/routes");

var sharing_1 = require("@dynamynd/sharing");

var user_1 = require("@dynamynd/user");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var components_1 = require("../../components");

var styled_1 = require("../../components/styled");

var store_1 = require("../../store");

var components_2 = require("./components");

var maxRefreshCount = 5;

var SurveyResultsScreen =
/** @class */
function (_super) {
  __extends(SurveyResultsScreen, _super);

  function SurveyResultsScreen(props) {
    var _a;

    var _this = _super.call(this, props) || this;

    _this.numberOfTipsToRender = 3;
    _this.summarySectionTitle = 'Explore wise uses of Your Four natural abilities';

    _this.loadData = function () {
      var _a = _this.props,
          resultsModuleData = _a.resultsModuleData,
          resultsModuleName = _a.resultsModuleName,
          personId = _a.user.PersonId,
          getModuleDetails = _a.getModuleDetails; // fetch the data for the resultsModule if it's still not available

      if (personId && utilities_1.isEmpty(resultsModuleData) && resultsModuleName) {
        getModuleDetails(resultsModuleName);
      } // start the analytics session


      _this.props.startActiveModuleViewSession({
        moduleId: resultsModuleName
      });
    };

    _this.setSectionHeightState = function (sectionName, height) {
      _this.setState(function (prevState) {
        var _a;

        return __assign(__assign({}, prevState), {
          sectionHeights: __assign(__assign({}, prevState.sectionHeights), (_a = {}, _a[sectionName] = height, _a))
        });
      });
    };

    _this.scrollToTop = function () {
      if (_this.scrollViewRef.current) {
        _this.scrollViewRef.current.scrollTo({
          x: 0,
          y: 0,
          animated: true
        });
      }
    };

    _this.scrollToNextSection = function (currentSection) {
      var sectionHeights = _this.state.sectionHeights;
      var scrollSectionsOrder = [modules_1.ModuleSectionViewsEnum.intro, modules_1.ModuleSectionViewsEnum.overview, modules_1.ModuleSectionViewsEnum.tubes, modules_1.ModuleSectionViewsEnum.info, modules_1.ModuleSectionViewsEnum.firstMo, modules_1.ModuleSectionViewsEnum.secondMo, modules_1.ModuleSectionViewsEnum.thirdMo, modules_1.ModuleSectionViewsEnum.fourthMo];
      var sectionIndex = scrollSectionsOrder.indexOf(currentSection) + 1;
      var scrollToHeight = scrollSectionsOrder.slice(0, sectionIndex).reduce(function (acc, section) {
        // in order to scroll enough the info section we need to scroll further than the height of overview:
        if (currentSection === modules_1.ModuleSectionViewsEnum.overview) {
          return acc + sectionHeights[section] * 1.05;
        }

        return acc + sectionHeights[section];
      }, 0);

      if (_this.scrollViewRef.current) {
        _this.scrollViewRef.current.scrollTo({
          x: 0,
          y: scrollToHeight,
          animated: true
        });
      }
    };

    _this.getDisplayName = function () {
      var user = _this.props.user;
      return user_1.userService.getDisplayName(user);
    };

    _this.getMessageText = function () {
      var errorState = _this.props.errorState;
      var refreshExhausted = _this.state.refreshExhausted;

      if (errorState === core_1.ErrorStateEnum.error) {
        return "Something went wrong. " + (refreshExhausted ? ' Try again later.' : '');
      }

      return 'We are working to create your results...';
    };

    _this.refreshResults = function () {
      var refreshExhausted = _this.state.refreshExhausted;

      if (!refreshExhausted) {
        // end the current analytics session
        _this.props.revokeActiveModuleViewSession();

        _this.loadData();

        _this.setState(function (prevState) {
          var prevRefreshCount = prevState.refreshCount;
          var nextRefreshCount = prevRefreshCount + 1;
          return {
            refreshCount: nextRefreshCount,
            refreshExhausted: nextRefreshCount >= maxRefreshCount
          };
        });
      }
    };

    _this.renderIntro = function () {
      var _a = _this.props,
          purchasingModulesViewState = _a.purchasingModulesViewState,
          errorState = _a.errorState,
          owl = _a.resultsModuleData.owl;
      var refreshExhausted = _this.state.refreshExhausted;

      var resultsHasData = _this.resultsHasData();

      var messageText = _this.getMessageText();

      var introContent = resultsHasData ? _this.props.resultsModuleData.introSection : '';

      if (purchasingModulesViewState) {
        return react_1.default.createElement(components_lib_1.LoadingIndicator, {
          size: "large",
          fullScreen: true
        });
      }

      return react_1.default.createElement(components_2.SectionResultsIntro, {
        messageText: messageText,
        name: _this.getDisplayName(),
        resultsHasData: resultsHasData,
        getModulesFailed: errorState === core_1.ErrorStateEnum.error,
        refreshResults: _this.refreshResults,
        refreshExhausted: refreshExhausted,
        content: introContent,
        imageUrl: owl,
        scrollToElementHandler: _this.scrollToNextSection,
        onLayoutHandler: _this.setSectionHeightState
      });
    };

    _this.renderContent = function (showContent) {
      var _a = _this.props,
          resultsModuleData = _a.resultsModuleData,
          renderModules = _a.renderModules,
          purchasingModulesViewState = _a.purchasingModulesViewState,
          resultsModuleName = _a.resultsModuleName;

      if (purchasingModulesViewState) {
        return null;
      }

      if (_this.resultsHasData()) {
        return renderModules({
          resultsModuleName: resultsModuleName,
          moduleData: resultsModuleData,
          summarySectionTitle: _this.summarySectionTitle,
          numberOfTipsToRender: _this.numberOfTipsToRender,
          showPaymentModal: showContent,
          scrollToSectionHandler: _this.scrollToNextSection,
          setSectionHeightState: _this.setSectionHeightState,
          withCustomAnalytics: true,
          withAvailableModules: true
        });
      }
    };

    _this.showModal = function () {
      _this.setState({
        hideModal: !_this.state.hideModal
      });
    };

    _this.scrollViewRef = react_1.default.createRef();
    _this.state = {
      refreshCount: 0,
      refreshExhausted: false,
      sectionHeights: (_a = {}, _a[modules_1.ModuleSectionViewsEnum.intro] = 0, _a[modules_1.ModuleSectionViewsEnum.overview] = 0, _a[modules_1.ModuleSectionViewsEnum.tubes] = 0, _a[modules_1.ModuleSectionViewsEnum.info] = 0, _a[modules_1.ModuleSectionViewsEnum.firstMo] = 0, _a[modules_1.ModuleSectionViewsEnum.secondMo] = 0, _a[modules_1.ModuleSectionViewsEnum.thirdMo] = 0, _a[modules_1.ModuleSectionViewsEnum.fourthMo] = 0, _a),
      hideModal: true
    };
    return _this;
  }

  SurveyResultsScreen.prototype.componentDidMount = function () {
    this.setActiveModule();
    this.loadData(); // const {activeQuestionId} = this.props;
  };

  SurveyResultsScreen.prototype.shouldComponentUpdate = function (nextProps, nextState) {
    var currentSectionHeights = this.state.sectionHeights;
    var nextSectionHeights = nextState.sectionHeights;

    if (!utilities_1.isEqual(currentSectionHeights, nextSectionHeights)) {
      return false;
    }

    return true;
  };

  SurveyResultsScreen.prototype.componentDidUpdate = function (prevProps) {
    var prevPersonId = prevProps.user.PersonId,
        prevResultsModuleName = prevProps.resultsModuleName;
    var _a = this.props,
        currentPersonId = _a.user.PersonId,
        resultsModuleName = _a.resultsModuleName,
        getAvailableModules = _a.getAvailableModules,
        getModuleDetails = _a.getModuleDetails,
        revokeActiveModuleViewSession = _a.revokeActiveModuleViewSession,
        startActiveModuleViewSession = _a.startActiveModuleViewSession,
        resultsModuleData = _a.resultsModuleData; // fetch results module data once we have the module name loaded in the store

    if (!prevResultsModuleName && resultsModuleName) {
      getModuleDetails(resultsModuleName);
    }

    if (!prevPersonId && prevPersonId !== currentPersonId) {
      getModuleDetails(resultsModuleName);
      getAvailableModules(); // end the current analytics session and start new one with the different user id

      revokeActiveModuleViewSession();
      startActiveModuleViewSession({
        moduleId: resultsModuleName
      });
    }

    if (!utilities_1.isEmpty(resultsModuleData)) {
      this.setSharingDataValues();
    }

    this.scrollToTop();
  };

  SurveyResultsScreen.prototype.componentWillUnmount = function () {
    // end the current analytics session
    var resultsModuleName = this.props.resultsModuleName;
    this.props.revokeActiveModuleViewSession({
      moduleId: resultsModuleName
    });
    this.props.setActiveModule('');
  };

  SurveyResultsScreen.prototype.setSharingDataValues = function () {
    var _a = this.props,
        withModuleShare = _a.withModuleShare,
        title = _a.activeModuleSharingDataValues.title,
        _b = _a.resultsModuleData,
        moduleId = _b.moduleId,
        sharingMessage = _b.sharingMessage,
        profileId = _a.user.ID;
    var url = this.props.getSharingUrl(sharing_1.SharedQsParamsEnum.mid + "=" + moduleId, sharing_1.SharedQsParamsEnum.pid + "=" + profileId, "/" + routes_1.RoutesKeysEnum.shared + "/" + routes_1.RoutesKeysEnum.sharedResultsModule);
    withModuleShare({
      url: url,
      title: title,
      description: sharingMessage
    });
  };

  SurveyResultsScreen.prototype.setActiveModule = function () {
    var _a = this.props,
        resultsModuleName = _a.resultsModuleName,
        setActiveModule = _a.setActiveModule;
    setActiveModule(resultsModuleName);
  };

  SurveyResultsScreen.prototype.resultsHasData = function () {
    var resultsModuleData = this.props.resultsModuleData;
    return !utilities_1.isEmpty(resultsModuleData);
  };

  SurveyResultsScreen.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        _b = _a.screenProps.components,
        WebViewComponent = _b.WebViewComponent,
        IFrameComponent = _b.IFrameComponent,
        purchasingModulesViewState = _a.purchasingModulesViewState; // TODO refactor into better solution

    var content1 = 'Our purpose is to enable you to be this child’s advocate. We hope the information we are providing will allow you to champion their strengths and encourage him/her/them to use them well.\n\n';
    var content2 = 'We are sharing this information with you so you can comfort and protect this child when the world neither recognizes nor appreciates who they are.\n\n';
    var content3 = 'By anticipating what your child WILL do, it will likely be easier for you to be ready with a timely helping hand and meaningful, nurturing words.\n\n';
    var content4 = 'Their self-efficacy, or belief in their innate abilities, will give them a sense of purpose that we hope will protect them from bullying, misunderstandings, and wrongful criticisms.\n\n';
    var content5 = 'For them to know the depth of their ever-present abilities is the greatest gift you will ever give them.\n\n';
    var content6 = 'I am personally so thankful that you are providing this information.\n\n';
    var content7 = 'Kathy Kolbe';
    var modalContent = content1 + content2 + content3 + content4 + content5 + content6 + content7;
    return react_1.default.createElement(responsive_1.ResponsiveContext.Consumer, null, function (_a) {
      var activeBreakpoint = _a.activeBreakpoint;
      return react_1.default.createElement(content_1.WithAdditionalContent, {
        WebViewComponent: WebViewComponent,
        IFrameComponent: IFrameComponent,
        includeFooter: true
      }, function (_a) {
        var showContent = _a.showContent;
        return react_1.default.createElement(react_1.Fragment, null, react_1.default.createElement(react_native_1.ScrollView, {
          ref: _this.scrollViewRef
        }, react_1.default.createElement(components_lib_1.ModulesBackground, {
          shouldExpand: purchasingModulesViewState,
          onLayoutHandler: function onLayoutHandler(event) {
            var introHeight = event.nativeEvent.layout.height;

            _this.setSectionHeightState(modules_1.ModuleSectionViewsEnum.intro, introHeight);
          }
        }, react_1.default.createElement(components_1.WithModal, {
          releaseButtonLabel: "I GOT IT",
          label: "",
          content: modalContent,
          hideModal: _this.state.hideModal,
          modalClose: _this.showModal
        }), react_1.default.createElement(components_lib_1.Header, null, react_1.default.createElement(components_lib_1.LogoMascot, {
          color: "white",
          version: {
            sm: 'text',
            lg: 'image'
          },
          size: "xlarge"
        }), react_1.default.createElement(user_1.UserProfileBox, {
          showPrivacy: function showPrivacy() {
            return showContent(content_1.ContentPagesEnum.privacy);
          },
          showTerms: function showTerms() {
            return showContent(content_1.ContentPagesEnum.terms);
          }
        })), _this.renderIntro()), _this.renderContent(showContent)), activeBreakpoint !== responsive_1.ActiveBreakpointEnum.small ? react_1.default.createElement("div", {
          className: "modal-container"
        }, react_1.default.createElement(styled_1.WrapperBottomRight, {
          onPress: _this.showModal
        }, react_1.default.createElement(styled_1.ImageStyled, {
          source: {
            uri: components_lib_1.appConfig.images.mascot
          },
          resizeMode: "stretch"
        })), react_1.default.createElement(styled_1.QuestionWrapperRight, {
          onPress: _this.showModal
        }, react_1.default.createElement(styled_1.IconWrapper, null, react_1.default.createElement(components_lib_1.Icon, {
          name: 'information-outline',
          size: responsive_1.isMedium(activeBreakpoint) ? components_lib_1.IconSizeEnum.small : components_lib_1.IconSizeEnum.medium
        })), react_1.default.createElement(styled_1.ImageWrapper, null, react_1.default.createElement(styled_1.ImageStyled, {
          source: {
            uri: components_lib_1.appConfig.images.instructionsBackground
          },
          resizeMode: "stretch"
        })))) : null);
      });
    });
  };

  return SurveyResultsScreen;
}(react_1.default.Component);

exports.SurveyResultsScreen = SurveyResultsScreen;

var mapStateToProps = function mapStateToProps(state) {
  return {
    user: user_1.userProfileSelector(state),
    resultsModuleName: modules_1.resultsModuleNameSelector(state),
    resultsModuleData: modules_1.resultsModuleDetailsViewModelSelector(state),
    purchasingModulesViewState: modules_1.purchasingModulesViewStateSelector(state),
    errorState: store_1.resultsErrorStateSelector(state),
    activeModuleSharingDataValues: modules_1.getActiveModuleSharingDataValues(state),
    activeQuestiontId: store_1.activeQuestionIdSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getAvailableModules: redux_1.bindActionCreators(modules_1.getAvailableModulesActionCreator, dispatch),
    getModuleDetails: redux_1.bindActionCreators(modules_1.getModuleDetailsActionCreator, dispatch),
    setActiveModule: redux_1.bindActionCreators(modules_1.setActiveModuleActionCreator, dispatch),
    startActiveModuleViewSession: redux_1.bindActionCreators(modules_1.startActiveModuleViewSessionActionCreator, dispatch),
    revokeActiveModuleViewSession: redux_1.bindActionCreators(modules_1.revokeActiveModuleViewSessionActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(modules_1.withSectionModulesRender, sharing_1.withAddThisSharing, connector);
exports.default = hoc(SurveyResultsScreen);