"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ActionTypes;

(function (ActionTypes) {
  ActionTypes["DYNAMYND_LOGIN_ATTEMPT"] = "[authentication] user login attempt";
  ActionTypes["DYNAMYND_LOGIN_SUCCESS"] = "[authentication] user login";
  ActionTypes["DYNAMYND_REGISTER_USER_SUBMIT"] = "[authentication] register submit";
  ActionTypes["DYNAMYND_REGISTER_USER_RESPONSE"] = "[authentication] register response";
  ActionTypes["DYNAMYND_REGISTER_USER_FAIL"] = "[authentication] register fail";
  ActionTypes["DYNAMYND_VALID_EMAIL_CHECK"] = "[authentication] valid email check";
  ActionTypes["DYNAMYND_VALID_EMAIL_RESPONSE"] = "[authentication] valid email response";
  ActionTypes["DYNAMYND_VALID_PHONE_CHECK"] = "[authentication] valid phone check";
  ActionTypes["DYNAMYND_VALID_PHONE_RESPONSE"] = "[authentication] valid phone response";
  ActionTypes["RESET_PASSWORD_UPDATE_STAGE"] = "[authentication] update the stage of the reset password flow";
  ActionTypes["RESET_PASSWORD_INITIATE_FLOW"] = "[authentication] initiate the reset password flow";
  ActionTypes["RESET_PASSWORD_VALIDATE_CODE"] = "[authentication] validate the PIN code | reset password flow";
  ActionTypes["UPDATE_USER_PASSWORD"] = "[authentication] update the user password | reset password flow";
  ActionTypes["RESET_REGISTER_USER_VIEW_STATE"] = "[authentication] reset the values of the register user view state";
  ActionTypes["SET_INITIAL_VALUES_TO_RESET_PASSWORD_VIEW_STATE"] = "[authentication] reset the values of the password reset view state";
})(ActionTypes = exports.ActionTypes || (exports.ActionTypes = {}));