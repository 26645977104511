"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var api_1 = require("@dynamynd/api");

var AuthApiService =
/** @class */
function (_super) {
  __extends(AuthApiService, _super);

  function AuthApiService(baseUrl, authUrl) {
    var _this = _super.call(this, baseUrl) || this;

    _this.authUrl = authUrl;
    return _this;
  }

  AuthApiService.prototype.getAuthToken = function (email, phone, password) {
    var url = this.authUrl + "/api/login";
    return this.postForm(url, {
      email: email,
      phone: phone,
      password: password
    }, api_1.HttpApi.requestType.withoutCredentials);
  };

  AuthApiService.prototype.emailExists = function (email) {
    var url = this.baseUrl + "/person.cfc";
    var body = {
      email: email,
      method: 'emailExists'
    };
    return this.postForm(url, body);
  };

  AuthApiService.prototype.phoneExists = function (phone) {
    var url = this.baseUrl + "/person.cfc";
    var body = {
      phone: phone,
      method: 'phoneExists'
    };
    return this.postForm(url, body);
  };

  AuthApiService.prototype.submitRegistration = function (values) {
    var firstName = values.firstName,
        lastName = values.lastName,
        dateOfBirth = values.dateOfBirth,
        loginType = values.loginType,
        email = values.email,
        phoneNumber = values.phoneNumber,
        password = values.password,
        passwordConfirm = values.passwordConfirm,
        termsAccepted = values.termsAccepted,
        avatar = values.avatar,
        nickName = values.nickName,
        accessCode = values.accessCode,
        country = values.country,
        zipCode = values.zipCode;
    var url = this.baseUrl + "/person.cfc";
    return this.postForm(url, {
      method: 'registerUser',
      FirstName: firstName,
      LastName: lastName,
      LoginType: loginType,
      Email: email,
      CellPhone: phoneNumber,
      AgreeToTerms: termsAccepted ? '1' : '0',
      Password: password,
      Password2: passwordConfirm ? passwordConfirm : password,
      DOB: dateOfBirth,
      Avatar: avatar,
      Nickname: nickName,
      AccessCode: accessCode,
      Country: country,
      Zip: zipCode
    });
  };

  AuthApiService.prototype.initiateResetPasswordFlow = function (payload) {
    var url = this.baseUrl + "/person.cfc";
    return this.postForm(url, __assign(__assign({}, payload), {
      method: 'getPasswordResetPin'
    }), api_1.HttpApi.requestType.withoutCredentials);
  };

  AuthApiService.prototype.updateUserPassword = function (payload) {
    var url = this.baseUrl + "/person.cfc";
    return this.postForm(url, __assign(__assign({}, payload), {
      method: 'resetPasswordWithPin'
    }), api_1.HttpApi.requestType.withoutCredentials);
  };

  return AuthApiService;
}(api_1.HttpApi);

exports.AuthApiService = AuthApiService;