"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actions_1 = require("../actions");

var initialAuthState_1 = require("./../state/initialAuthState"); // todo separate handlers with better typing


exports.registerUserReducer = function (state, action) {
  if (state === void 0) {
    state = initialAuthState_1.initialAuthState.registerUser;
  }

  if (!action) return state;
  var type = action.type,
      payload = action.payload;

  switch (type) {
    case actions_1.ActionTypes.DYNAMYND_VALID_EMAIL_CHECK:
      return __assign(__assign({}, state), {
        emailValidation: __assign(__assign({}, state.emailValidation), {
          email: payload.state,
          validated: false
        })
      });

    case actions_1.ActionTypes.DYNAMYND_VALID_EMAIL_RESPONSE:
      return __assign(__assign({}, state), {
        emailValidation: __assign(__assign({}, payload.state), {
          validated: true
        })
      });

    case actions_1.ActionTypes.DYNAMYND_VALID_PHONE_CHECK:
      return __assign(__assign({}, state), {
        phoneValidation: __assign(__assign({}, state.phoneValidation), {
          phone: payload.state,
          validated: false
        })
      });

    case actions_1.ActionTypes.DYNAMYND_VALID_PHONE_RESPONSE:
      return __assign(__assign({}, state), {
        phoneValidation: __assign(__assign({}, payload.state), {
          validated: true
        })
      });

    case actions_1.ActionTypes.DYNAMYND_REGISTER_USER_SUBMIT:
    case actions_1.ActionTypes.DYNAMYND_REGISTER_USER_RESPONSE:
    case actions_1.ActionTypes.DYNAMYND_REGISTER_USER_FAIL:
    case actions_1.ActionTypes.DYNAMYND_LOGIN_ATTEMPT:
    case actions_1.ActionTypes.DYNAMYND_LOGIN_SUCCESS:
    case actions_1.ActionTypes.RESET_REGISTER_USER_VIEW_STATE:
    case core_1.ActionTypes.DYNAMYND_ERROR:
    case core_1.ActionTypes.DYNAMYND_NAVIGATE:
    case core_1.ActionTypes.DYNAMYND_LOGOUT_USER:
      var initialRegisterUserState = initialAuthState_1.initialAuthState.registerUser;
      return __assign({}, initialRegisterUserState);

    default:
      return state;
  }
};