"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var components_1 = require("../../components");

var config_1 = require("../../config");

var enums_1 = require("../../enums");

var services_1 = require("../../services");

var sections_1 = require("../sections");

function withSectionModulesRender(WrappedComponent) {
  var WithSectionModulesRenderHOC =
  /** @class */
  function (_super) {
    __extends(WithSectionModulesRenderHOC, _super);

    function WithSectionModulesRenderHOC() {
      var _this = _super !== null && _super.apply(this, arguments) || this;

      _this.renderModules = function (options) {
        var moduleData = options.moduleData,
            summarySectionTitle = options.summarySectionTitle,
            resultsModuleName = options.resultsModuleName,
            numberOfTipsToRender = options.numberOfTipsToRender,
            showPaymentModal = options.showPaymentModal,
            _scrollToSectionHandler = options.scrollToSectionHandler,
            setSectionHeightState = options.setSectionHeightState,
            withCustomAnalytics = options.withCustomAnalytics,
            withAvailableModules = options.withAvailableModules;
        var moduleName = moduleData.moduleName,
            type = moduleData.type,
            description = moduleData.description,
            colored4Tubes = moduleData.colored4Tubes,
            owl = moduleData.owl,
            tubesLeftTop = moduleData.tubesLeftTop,
            tubesLeftMid = moduleData.tubesLeftMid,
            tubesLeftBottom = moduleData.tubesLeftBottom,
            tubesRightHeader = moduleData.tubesRightHeader;

        var _a = config_1.moduleStateToComponentsContent(moduleName, moduleData, resultsModuleName),
            tubesHeader = _a.tubesHeader,
            naturalAdvantageHeader = _a.naturalAdvantageHeader;

        return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(sections_1.SectionOverview, {
          imageUrl: owl,
          type: type,
          description: description,
          tubesHeader: tubesHeader,
          naturalAdvantageHeader: naturalAdvantageHeader,
          onLayoutHandler: setSectionHeightState,
          scrollToSectionHandler: function scrollToSectionHandler() {
            return _scrollToSectionHandler(enums_1.ModuleSectionViewsEnum.overview);
          }
        }), react_1.default.createElement(sections_1.SectionTubes, {
          imageUrl: colored4Tubes,
          descriptionTop: tubesLeftTop,
          descriptionMid: tubesLeftMid,
          descriptionBottom: tubesLeftBottom,
          tubesHeader: tubesRightHeader,
          naturalAdvantageHeader: naturalAdvantageHeader,
          onLayoutHandler: setSectionHeightState,
          scrollToSectionHandler: function scrollToSectionHandler() {
            return _scrollToSectionHandler(enums_1.ModuleSectionViewsEnum.info);
          }
        }), _this.renderSectionModules(moduleData, setSectionHeightState, _scrollToSectionHandler, numberOfTipsToRender, withCustomAnalytics), react_1.default.createElement(components_1.SectionSummary, {
          title: summarySectionTitle,
          showPaymentModal: showPaymentModal,
          withAvailableModules: withAvailableModules
        }));
      };

      _this.renderSectionModules = function (moduleData, setSectionHeightState, _scrollToSectionHandler2, numberOfTipsToRender, withCustomAnalytics) {
        var mainFourModes = moduleData.mainFourModes,
            contentDisplayOrder = moduleData.contentDisplayOrder,
            moduleName = moduleData.moduleName; // const separate4Tubes = moduleData.seperate4Tubes as {[key: string]: string};

        var owlSeparate = moduleData.owlseperate;
        var owlImage = moduleData.owl;
        var viewOrderNames = [enums_1.ModuleSectionViewsEnum.firstMo, enums_1.ModuleSectionViewsEnum.secondMo, enums_1.ModuleSectionViewsEnum.thirdMo, enums_1.ModuleSectionViewsEnum.fourthMo]; // todo labels parsed from state

        return contentDisplayOrder.map(function (modeName, idx) {
          var colorConfig = services_1.modulesService.getModuleColors(modeName, contentDisplayOrder[idx + 1], idx === 0, idx === contentDisplayOrder.length - 1);
          return react_1.default.createElement(components_1.ModuleSection, {
            key: idx,
            image: owlSeparate && owlSeparate[modeName],
            owlImage: owlImage,
            moduleData: mainFourModes[modeName],
            layoutType: idx % 2 === 0 ? enums_1.ModuleSectionLayoutTypeEnum.image : enums_1.ModuleSectionLayoutTypeEnum.content,
            backgroundColor: colorConfig.background,
            rectangleColor: colorConfig.tips,
            user: name,
            sectionName: modeName,
            moduleName: moduleName,
            viewOrderName: viewOrderNames[idx],
            onLayoutHandler: setSectionHeightState,
            scrollToSectionHandler: function scrollToSectionHandler() {
              return _scrollToSectionHandler2(viewOrderNames[idx]);
            },
            numberOfTipsToRender: numberOfTipsToRender,
            withCustomAnalytics: withCustomAnalytics
          });
        });
      };

      return _this;
    }

    WithSectionModulesRenderHOC.prototype.render = function () {
      return react_1.default.createElement(WrappedComponent, __assign({}, this.props, {
        renderModules: this.renderModules
      }));
    };

    return WithSectionModulesRenderHOC;
  }(react_1.Component);

  return WithSectionModulesRenderHOC;
}

exports.withSectionModulesRender = withSectionModulesRender;