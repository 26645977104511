"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var actionTypes_1 = require("./actionTypes");

exports.loginAttemptActionCreator = function (loginParams) {
  return core_1.actionCreatorApiWithoutCredentialsMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_LOGIN_ATTEMPT,
    payload: loginParams
  });
};

exports.loginSuccessActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_LOGIN_SUCCESS
  });
};

exports.registerSubmitActionCreator = function (state) {
  return core_1.actionCreatorApiWithoutCredentialsMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_REGISTER_USER_SUBMIT,
    payload: {
      state: state
    }
  });
};

exports.registerResponseActionCreator = function (state) {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_REGISTER_USER_RESPONSE,
    payload: {
      state: state
    }
  });
};

exports.registerFailActionCreator = function (state) {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_REGISTER_USER_FAIL,
    payload: {
      state: state.message
    }
  });
};

exports.validEmailCheckActionCreator = function (state) {
  return core_1.actionCreatorApiWithoutCredentialsMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_VALID_EMAIL_CHECK,
    payload: {
      state: state
    }
  });
};

exports.validEmailResponseActionCreator = function (state) {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_VALID_EMAIL_RESPONSE,
    payload: {
      state: state
    }
  });
};

exports.validPhoneCheckActionCreator = function (state) {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_VALID_PHONE_CHECK,
    payload: {
      state: state
    }
  });
};

exports.validPhoneResponseActionCreator = function (state) {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ActionTypes.DYNAMYND_VALID_PHONE_RESPONSE,
    payload: {
      state: state
    }
  });
};

exports.updateResetPasswordStageActionActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ActionTypes.RESET_PASSWORD_UPDATE_STAGE
  });
};

exports.initiateResetPasswordFlowActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ActionTypes.RESET_PASSWORD_INITIATE_FLOW
  });
};

exports.updateUserPasswordActionCreator = function (payload) {
  return core_1.actionCreatorDefaultMetaType({
    payload: payload,
    type: actionTypes_1.ActionTypes.UPDATE_USER_PASSWORD
  });
};

exports.resetRegisterUserViewStateActionActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ActionTypes.RESET_REGISTER_USER_VIEW_STATE
  });
};

exports.setInitialValuesToResetPasswordViewStateActionCreator = function () {
  return core_1.actionCreatorDefaultMetaType({
    type: actionTypes_1.ActionTypes.SET_INITIAL_VALUES_TO_RESET_PASSWORD_VIEW_STATE
  });
};