"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __rest = this && this.__rest || function (s, e) {
  var t = {};

  for (var p in s) {
    if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0) t[p] = s[p];
  }

  if (s != null && typeof Object.getOwnPropertySymbols === "function") for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
    if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i])) t[p[i]] = s[p[i]];
  }
  return t;
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var modules_1 = require("@dynamynd/modules");

var routes_1 = require("@dynamynd/routes");

var survey_1 = require("@dynamynd/survey");

var user_1 = require("@dynamynd/user");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

function withPurchasedValidation(WrappedComponent) {
  var WithPurchasedValidationHOC =
  /** @class */
  function (_super) {
    __extends(WithPurchasedValidationHOC, _super);

    function WithPurchasedValidationHOC() {
      var _this = _super !== null && _super.apply(this, arguments) || this;

      _this.state = {
        ready: false
      };

      _this.hasAvailableModules = function () {
        var availableModules = _this.props.availableModules;
        return availableModules.length > 0;
      };

      _this.handleScreenAccess = function () {
        var _a;

        var _b = _this.props,
            resultsModuleMetaData = _b.resultsModuleMetaData,
            MO = _b.userProfile.MO;
        var isScored = survey_1.surveyService.isSurveyScored(MO);
        /*
            resultsModule has not been purchased yet.
            Navigate the user to the purchase page where he get access to the resultsModule
        */

        if (isScored && !resultsModuleMetaData.isUnlocked) {
          _this.props.navigateTo(routes_1.RoutesKeysEnum.purchaseModule, (_a = {}, _a[modules_1.ModulesService.moduleParam] = resultsModuleMetaData.id, _a));
        }

        _this.handleReadyState();
      };

      _this.handleReadyState = function () {
        if (!_this.state.ready && _this.hasAvailableModules()) {
          _this.setState({
            ready: true
          });
        }
      };

      return _this;
    }

    WithPurchasedValidationHOC.prototype.componentDidMount = function () {
      var getAvailableModules = this.props.getAvailableModules;
      var hasAvailableModules = this.hasAvailableModules();

      if (!hasAvailableModules) {
        getAvailableModules();
        return;
      }

      this.handleScreenAccess();
    };

    WithPurchasedValidationHOC.prototype.shouldComponentUpdate = function (nextProps) {
      var nextAvailableModules = nextProps.availableModules,
          nextUserProfile = nextProps.userProfile;
      return nextUserProfile.PersonId !== '' && nextAvailableModules.length > 0;
    };

    WithPurchasedValidationHOC.prototype.componentDidUpdate = function (prevProps) {
      var prevAvailableModules = prevProps.availableModules;
      var currentAvailableModules = this.props.availableModules;

      if (this.hasAvailableModules() && prevAvailableModules !== currentAvailableModules) {
        this.handleScreenAccess();
      }
    };

    WithPurchasedValidationHOC.prototype.render = function () {
      var ready = this.state.ready;
      if (!ready) return null;

      var _a = this.props,
          availableModules = _a.availableModules,
          userProfile = _a.userProfile,
          resultsModuleMetaData = _a.resultsModuleMetaData,
          navigateTo = _a.navigateTo,
          getAvailableModules = _a.getAvailableModules,
          rest = __rest(_a, ["availableModules", "userProfile", "resultsModuleMetaData", "navigateTo", "getAvailableModules"]);

      return react_1.default.createElement(WrappedComponent, __assign({}, rest));
    };

    return WithPurchasedValidationHOC;
  }(react_1.Component);

  var mapStateToProps = function mapStateToProps(state) {
    var availableModules = modules_1.modulesViewDataSelector(state);
    var userProfile = user_1.userProfileSelector(state);
    var resultsModuleMetaData = modules_1.resultsModuleMetaDataSelector(state);
    return {
      availableModules: availableModules,
      userProfile: userProfile,
      resultsModuleMetaData: resultsModuleMetaData
    };
  };

  var mapDispatchToProps = function mapDispatchToProps(dispatch) {
    return {
      navigateTo: redux_1.bindActionCreators(core_1.navigateActionCreator, dispatch),
      getAvailableModules: redux_1.bindActionCreators(modules_1.getAvailableModulesActionCreator, dispatch)
    };
  };

  var hoc = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
  return hoc(WithPurchasedValidationHOC);
}

exports.withPurchasedValidation = withPurchasedValidation;