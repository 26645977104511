"use strict";

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var react_1 = __importStar(require("react"));

var __1 = require("..");

var pageTitles = ['What is your Birthdate?', 'Create a Username and Password', 'Let’s know each other'];

exports.Under13Workflow = function (_a) {
  var activeBreakpoint = _a.activeBreakpoint,
      MaskedInputDate = _a.screenProps.components.MaskedInputDate,
      setPageTitle = _a.setPageTitle,
      onWorkflowExit = _a.onWorkflowExit,
      onWorkflowSuccess = _a.onWorkflowSuccess;

  var _b = components_lib_1.useWizard(),
      previousHandler = _b.previousHandler,
      nextHandler = _b.nextHandler,
      activeStep = _b.activeStep;

  var _c = react_1.useState({}),
      formData = _c[0],
      setFormData = _c[1]; // change the parent's page title when we change our internal step


  react_1.useEffect(function () {
    setPageTitle(pageTitles[activeStep]);
  }, [activeStep]);

  var handleWizardSuccess = function handleWizardSuccess() {
    onWorkflowSuccess(formData);
  };

  return react_1.default.createElement(components_lib_1.Steps, {
    activeStep: activeStep
  }, react_1.default.createElement(__1.BirthdatePicker, {
    activeBreakpoint: activeBreakpoint,
    DateInputComponent: MaskedInputDate,
    onSubmit: function onSubmit(dob, isUnder13) {
      if (isUnder13) {
        // stay here, go to next screen
        setFormData(__assign(__assign({}, formData), {
          dateOfBirth: dob
        }));
        nextHandler();
      } else {
        // leave, go to regular signup screen
        onWorkflowExit(dob);
      }
    }
  }), react_1.default.createElement(__1.UserNameRegistrationForm, __assign({}, {
    activeBreakpoint: activeBreakpoint,
    previousHandler: previousHandler,
    nextHandler: nextHandler
  }, {
    onSubmit: function onSubmit(data) {
      setFormData(__assign(__assign({}, formData), data));
      nextHandler();
    }
  })), react_1.default.createElement(__1.ShortPersonalDetailsForm, __assign({}, {
    activeBreakpoint: activeBreakpoint,
    previousHandler: previousHandler,
    nextHandler: nextHandler
  }, {
    onSubmit: function onSubmit(data) {
      setFormData(__assign(__assign({}, formData), data));
      handleWizardSuccess();
    }
  })));
};