"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var redux_observable_1 = require("redux-observable");

var initiateResetPasswordFlow_epic_1 = require("./initiateResetPasswordFlow.epic");

var logout_epic_1 = require("./logout.epic");

var registration_epic_1 = require("./registration.epic");

var updateUserPassword_epic_1 = require("./updateUserPassword.epic");

exports.authenticationEpic = redux_observable_1.combineEpics(registration_epic_1.submitRegistrationEpic, registration_epic_1.registrationResponseEpic, registration_epic_1.emailExistsEpic, registration_epic_1.phoneExistsEpic, logout_epic_1.logoutEpic, registration_epic_1.registrationFailEpic, initiateResetPasswordFlow_epic_1.initiateResetPasswordFlowEpic, updateUserPassword_epic_1.updateUserPasswordEpic);