"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var content_parser_1 = require("@dynamynd/content-parser");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var enums_1 = require("../../enums");

var services_1 = require("../../services");

var styled_1 = require("../styled"); // todo refactor


var SurveyScaleQuestion =
/** @class */
function (_super) {
  __extends(SurveyScaleQuestion, _super);

  function SurveyScaleQuestion() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.state = {
      selected: ''
    }; // todo refactor

    _this.pressHandler = function (optionId) {
      return function () {
        var selected = _this.state.selected;

        _this.setState({
          selected: selected === optionId ? '' : optionId
        });

        _this.updateResponseHandler({
          scale: optionId
        });
      };
    };

    _this.renderOptions = function (optionId, label) {
      var disabled = _this.props.disabled;
      var selected = _this.state.selected; // todo move to theme

      var isSelected = selected === optionId;
      var textColor = isSelected ? '#fff' : '#000';
      var selectedCircle = selected.length > 0 && isSelected;
      var backgroundColor = isSelected ? '#dd7d38' : '#fff';
      return react_1.default.createElement(styled_1.ScaleQuestionOption, {
        key: optionId,
        onPress: _this.pressHandler(optionId),
        textColor: textColor,
        selectedCircle: selectedCircle,
        backgroundColor: backgroundColor,
        text: label,
        disabled: isSelected || disabled
      });
    };

    return _this;
  }

  SurveyScaleQuestion.prototype.componentDidMount = function () {
    var _a = this.props,
        response = _a.response,
        options = _a.question.options;

    if (!utilities_1.isEmpty(response)) {
      this.syncResponseWithState(options, response);
    }
  };

  SurveyScaleQuestion.prototype.componentDidUpdate = function (prevProps) {
    this.propsUpdateHandler(prevProps);
  };

  SurveyScaleQuestion.prototype.propsUpdateHandler = function (prevProps) {
    var _a = this.props,
        _b = _a.response,
        currentResponseId = _b.ID,
        currentResponseValue = _b.Response,
        options = _a.question.options;
    var _c = prevProps.response,
        prevResponseId = _c.ID,
        prevResponseValue = _c.Response;

    if (utilities_1.isUndefined(currentResponseValue) && utilities_1.isUndefined(prevResponseValue)) {
      return;
    }

    if (utilities_1.isUndefined(currentResponseValue) && this.responseHasValue(this.state.selected)) {
      this.setState({
        selected: ''
      });
      this.updateResponseHandler({
        scale: ''
      });
      return;
    }

    if (currentResponseValue !== prevResponseValue || currentResponseId !== prevResponseId) {
      this.syncResponseWithState(options, this.props.response);
      return;
    }

    if (currentResponseValue === prevResponseValue && currentResponseId !== prevResponseId) {
      var selected = this.state.selected;
      this.updateResponseHandler({
        scale: selected
      });
      return;
    }
  };

  SurveyScaleQuestion.prototype.responseHasValue = function (selected) {
    return selected !== '';
  };

  SurveyScaleQuestion.prototype.syncResponseWithState = function (options, response) {
    var values = response.Response;
    if (!values) return;

    if (values.length) {
      var scale = services_1.surveyService.getOptionIdByValue(options, values);
      this.setState({
        selected: scale
      });
      this.updateResponseHandler({
        scale: scale
      });
    }
  };

  SurveyScaleQuestion.prototype.updateResponseHandler = function (answerOptionIds) {
    var state = {
      answerOptionIds: answerOptionIds,
      shouldRecordResponse: this.shouldRecordResponse(answerOptionIds.scale),
      type: enums_1.SurveyQuestionTypeEnum.scale
    };
    this.props.updateResponse(state);
  };

  SurveyScaleQuestion.prototype.shouldRecordResponse = function (value) {
    /// values are different than those on the component state
    var _a = this.props,
        propsResponseValue = _a.response.Response,
        options = _a.question.options;
    var valuesAreDifferent = true;

    if (!utilities_1.isEmpty(propsResponseValue)) {
      var stateScale = services_1.surveyService.getOptionIdByValue(options, propsResponseValue);
      valuesAreDifferent = value !== stateScale;
    }

    return this.responseHasValue(value) && valuesAreDifferent;
  };

  SurveyScaleQuestion.prototype.render = function () {
    var _this = this;

    var _a = this.props.question,
        label = _a.question,
        options = _a.options;
    var _b = this.props,
        questionNumber = _b.questionNumber,
        disabled = _b.disabled;
    return react_1.default.createElement(styled_1.QuestionContainer, {
      disabled: disabled
    }, react_1.default.createElement(styled_1.TextTitle, null, react_1.default.createElement(styled_1.QuestionNumberLabel, null, questionNumber, ".\xA0"), react_1.default.createElement(content_parser_1.ParsedContent, {
      content: label
    })), react_1.default.createElement(styled_1.ScaleViewWrapper, null, options.map(function (_a) {
      var optionId = _a.optionId,
          option = _a.option;
      return _this.renderOptions(optionId, option);
    })));
  };

  return SurveyScaleQuestion;
}(react_1.Component);

exports.SurveyScaleQuestion = SurveyScaleQuestion;