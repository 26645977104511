"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
}); // todo add all the fonts here

exports.defaultTheme = {
  typography: {
    baseFontSize: '16px',
    button: {
      fontSize: 14,
      lineHeight: 32
    },
    link: {
      fontSize: 14,
      lineHeight: 16
    }
  },
  colors: {
    labelActive: '#2E4D92',
    label: '#626971',
    title: '#262B33',
    buttonText: '#FFFFFF',
    white: '#FFFFFF',
    purple: '#562685',
    gray: '#9298A1',
    red: '#FF5348',
    main: {
      jade: '#00BF6C',
      saphire: '#2E4D92',
      purple: '#562685'
    },
    secondary: {
      purple: '#625587',
      tale: '#58B1A5',
      orange: '#DD7D38',
      apricot: '#ED8671'
    },
    system: {
      error: '#FF5348',
      information: '#4B91E1',
      success: '#00BF6C',
      warning: '#00BF6C'
    },
    dark: '#262B33',
    inputActive: '#5770AA',
    inputActiveShadow: 'rgba(0,0,0,0.3)'
  },
  backgroundColor: {
    whiteButton: 'rgba(255,255,255,0.03)',
    primaryButton: '#2E4D92',
    secondaryButton: '#DD7D38',
    mainBackground: '#F6E8D6',
    homeAndSurveyBackground: '#FEF9F8'
  },
  colorVariations: {
    jade: {
      alpha120: '#00AC61',
      alpha100: '#00BF6C',
      alpha80: '#33CC89',
      alpha60: '#66D9A7',
      alpha40: '#99E5C4',
      alpha20: '#CCF2E2',
      alpha5: '#F2FCF7'
    },
    purple: {
      alpha120: '#441770',
      alpha100: '#562685',
      alpha80: '#78519D',
      alpha60: '#9A7DB6',
      alpha40: '#BBA8CE',
      alpha20: '#DDD4E7',
      alpha5: '#F6F4F9'
    },
    tale: {
      alpha120: '#429B8F',
      alpha100: '#58B1A5',
      alpha80: '#79C1B7',
      alpha60: '#79C1B7',
      alpha40: '#BCE0DB',
      alpha20: '#DEEFED',
      alpha5: '#F6FBFA'
    },
    orange: {
      alpha120: '#D86C1F',
      alpha100: '#DD7D38',
      alpha80: '#E49760',
      alpha60: '#EBB188',
      alpha40: '#F1CBAF',
      alpha20: '#F8E5D7',
      alpha5: '#FDF8F5'
    },
    apricot: {
      alpha120: '#D57966',
      alpha100: '#ED8671',
      alpha80: '#F19E8D',
      alpha60: '#F4B6AA',
      alpha40: '#F8CFC6',
      alpha20: '#FBE7E3',
      alpha5: '#FEF9F8'
    },
    error: {
      alpha120: 'rgba(75, 145, 225, 1.2)',
      alpha100: '#FF5348',
      alpha80: '#FF756D',
      alpha60: '#FF9891',
      alpha40: '#FFBAB6',
      alpha20: '#FFDDDA',
      alpha5: '#FFEDEC'
    },
    information: {
      alpha120: '#4B91E1',
      alpha100: '#4B91E1',
      alpha80: '#6FA7E7',
      alpha60: '#93BDED',
      alpha40: '#B7D3F3',
      alpha20: '#DBE9F9',
      alpha5: '#EDF4FC'
    },
    success: {
      alpha120: '#00BF6C',
      alpha100: '#00BF6C',
      alpha80: '#33CC89',
      alpha60: '#66D9A7',
      alpha40: '#99E5C4',
      alpha20: '#CCF2E2',
      alpha5: '#E5F8F0'
    },
    warning: {
      alpha120: 'rgba(255, 199, 58, 1.2)',
      alpha100: '#FFC73A',
      alpha80: '#FFD261',
      alpha60: '#FFDD89',
      alpha40: '#FFE9B0',
      alpha20: '#FFF4D8',
      alpha5: '#FFF9EB'
    },
    greyscale: {
      alpha120: '#131313',
      alpha100: '#262B33',
      alpha80: '#626971',
      alpha60: '#9298A1',
      alpha40: '#E9EBEE',
      alpha20: '#F6F6F9',
      alpha5: '#FFFFFF'
    }
  },
  view: {
    logo: {
      small: {
        width: 160,
        height: 40
      },
      large: {
        width: 185,
        height: 44
      },
      xlarge: {
        width: 300,
        height: 72
      }
    }
  }
};