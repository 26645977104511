"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var core_1 = require("@dynamynd/core");

var formik_1 = require("formik");

var react_1 = __importStar(require("react"));

var Yup = __importStar(require("yup"));

var enums_1 = require("../../../enums");

var validation_1 = require("../../../validation");

var DescriptionParagraph_1 = require("./DescriptionParagraph");

var RequestLinkForm =
/** @class */
function (_super) {
  __extends(RequestLinkForm, _super);

  function RequestLinkForm() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.description = "Enter your email or phone number that you used to register. We'll send you an email or a message with code to reset your password.";
    _this.initialValues = {
      username: ''
    };
    _this.validationSchema = Yup.object().shape({
      username: Yup.string().test('usernameValidation', core_1.ValidationErrorMessagesEnum.invalidUsername, function (value) {
        return validation_1.phoneNumberWithoutParenthesesRegEx.test(value) || validation_1.emailRegEx.test(value);
      }).min(2).required(core_1.ValidationErrorMessagesEnum.requiredField)
    });

    _this.renderFields = function (props) {
      var isSubmitting = props.isSubmitting,
          setSubmitting = props.setSubmitting;
      var updating = _this.props.updating;

      if (updating !== isSubmitting) {
        setSubmitting(updating);
      }

      return react_1.default.createElement(components_lib_1.FormInput, __assign({
        name: enums_1.FieldNames.username,
        label: enums_1.FieldLabels.forgotPasswordUsername,
        isSecure: false,
        isDisabled: isSubmitting
      }, props));
    };

    return _this;
  }

  RequestLinkForm.prototype.render = function () {
    var _a = this.props,
        onSubmit = _a.onSubmit,
        renderForm = _a.renderForm;
    return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(DescriptionParagraph_1.DescriptionParagraph, null, this.description), react_1.default.createElement(formik_1.Formik, {
      initialValues: this.initialValues,
      onSubmit: onSubmit,
      validationSchema: this.validationSchema,
      enableReinitialize: true,
      render: renderForm(this.renderFields)
    }));
  };

  return RequestLinkForm;
}(react_1.PureComponent);

exports.default = components_lib_1.withFormUtils(RequestLinkForm);