"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

var _a;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var styled_components_1 = require("styled-components");

var enums_1 = require("../../enums");

var store_1 = require("../../store");

var MostLeastSurveyQuestion_1 = require("../MostLeastSurveyQuestion/MostLeastSurveyQuestion");

var styled_1 = require("../styled");

var SurveyScaleQuestion_1 = require("../SurveyScaleQuestion/SurveyScaleQuestion");

var questionsRenderMap = (_a = {}, _a[enums_1.SurveyQuestionTypeEnum.mostLeast] = MostLeastSurveyQuestion_1.MostLeastSurveyQuestion, _a[enums_1.SurveyQuestionTypeEnum.scale] = SurveyScaleQuestion_1.SurveyScaleQuestion, _a);

var QuestionBlock =
/** @class */
function (_super) {
  __extends(QuestionBlock, _super);

  function QuestionBlock(props) {
    var _this = _super.call(this, props) || this;

    _this.state = {
      recordResponse: {
        type: enums_1.SurveyQuestionTypeEnum.mostLeast,
        answerOptionIds: {
          scale: '',
          most: '',
          least: ''
        },
        shouldRecordResponse: false
      }
    };

    _this.updateResponseHandler = function (state) {
      _this.setState({
        recordResponse: state
      });
    };

    _this.handleNavigationButtonPress = function (direction) {
      return function () {
        var _a = _this.state.recordResponse,
            shouldRecordResponse = _a.shouldRecordResponse,
            answerOptionIds = _a.answerOptionIds;

        if (shouldRecordResponse) {
          _this.recordResponse(answerOptionIds, direction);

          return;
        } // if we don't have to save a response


        _this.props.changeActiveQuestion({
          type: direction
        });
      };
    };

    _this.recordResponse = function (answerOptionIds, navigateDirection) {
      var _a = _this.props.activeQuestion,
          questionSampleId = _a.questionSampleId,
          questionType = _a.questionType;

      _this.props.recordQuestionResponse({
        answerOptionIds: answerOptionIds,
        questionType: questionType,
        navigateDirection: navigateDirection,
        questionId: questionSampleId
      });
    };

    _this.renderQuestion = function () {
      var _a = _this.props,
          activeQuestionNumber = _a.activeQuestionNumber,
          activeQuestion = _a.activeQuestion,
          activeQuestionResponse = _a.activeQuestionResponse,
          updating = _a.updating;
      if (utilities_1.isEmpty(activeQuestion)) return react_1.default.createElement(components_lib_1.LoadingIndicator, {
        size: "large"
      });
      var QuestionComponent = questionsRenderMap[activeQuestion.questionType];
      return react_1.default.createElement(QuestionComponent, {
        question: activeQuestion,
        updateResponse: _this.updateResponseHandler,
        response: activeQuestionResponse,
        questionNumber: activeQuestionNumber,
        disabled: updating
      });
    };

    _this.activeQuestionHasResponse = function () {
      var activeQuestionResponse = _this.props.activeQuestionResponse;
      return !utilities_1.isEmpty(activeQuestionResponse);
    };

    _this.renderActionButtons = function () {
      var _a = _this.props,
          _b = _a.questionPositionState,
          isFirst = _b.isFirst,
          isLast = _b.isLast,
          activeQuestion = _a.activeQuestion,
          theme = _a.theme,
          updating = _a.updating;
      if (utilities_1.isEmpty(activeQuestion)) return null;

      var isNextSaveDisabled = _this.setNextAndSaveButtonsDisableState();

      return react_1.default.createElement(react_1.default.Fragment, null, !isFirst && react_1.default.createElement(components_lib_1.Button, {
        type: components_lib_1.ButtonTypeEnum.link,
        text: "Previous",
        uppercase: true,
        onPress: _this.handleNavigationButtonPress(enums_1.ChangeQuestionTypeEnum.prev),
        disabled: updating,
        customColor: theme.colors.secondary.orange
      }), react_1.default.createElement(styled_1.ButtonSpacerWithMargin, null), isLast ? react_1.default.createElement(components_lib_1.Button, {
        type: components_lib_1.ButtonTypeEnum.secondary,
        text: "All Done",
        uppercase: false,
        onPress: _this.handleNavigationButtonPress(enums_1.ChangeQuestionTypeEnum.score),
        disabled: isNextSaveDisabled,
        loading: updating
      }) : react_1.default.createElement(components_lib_1.Button, {
        type: components_lib_1.ButtonTypeEnum.secondary,
        text: "Next",
        uppercase: true,
        onPress: _this.handleNavigationButtonPress(enums_1.ChangeQuestionTypeEnum.next),
        disabled: isNextSaveDisabled,
        loading: updating,
        icon: {
          name: 'arrow-right',
          position: components_lib_1.ButtonIconPositionEnum.right,
          size: components_lib_1.IconSizeEnum.small,
          color: theme.colors.white
        }
      }));
    };

    _this.setNextAndSaveButtonsDisableState = function () {
      var updating = _this.props.updating;
      var shouldRecordResponse = _this.state.recordResponse.shouldRecordResponse;

      if (updating) {
        return true;
      }

      var hasResponse = _this.activeQuestionHasResponse();

      if (hasResponse || !hasResponse && shouldRecordResponse) {
        return false;
      }

      return true;
    };

    return _this;
  }

  QuestionBlock.prototype.render = function () {
    return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(styled_1.SurveyViewWrapper, null, react_1.default.createElement(styled_1.SurveyQuestionContainer, null, this.renderQuestion())), react_1.default.createElement(styled_1.TakerButtonsRowWrapper, null, this.renderActionButtons()));
  };

  return QuestionBlock;
}(react_1.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    questionPositionState: store_1.questionPositionSelector(state),
    activeQuestionResponse: store_1.activeQuestionResponseSelector(state),
    activeQuestionNumber: store_1.activeQuestionNumberSelector(state),
    activeQuestion: store_1.activeQuestionSelector(state),
    updating: store_1.surveyUpdatingSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    recordQuestionResponse: redux_1.bindActionCreators(store_1.recordQuestionResponseActionCreator, dispatch),
    changeActiveQuestion: redux_1.bindActionCreators(store_1.changeActiveQuestionActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, styled_components_1.withTheme);
exports.default = hoc(QuestionBlock);