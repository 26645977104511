"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var core_1 = require("@dynamynd/core");

var features_1 = require("@dynamynd/features");

var routes_1 = require("@dynamynd/routes");

var user_1 = require("@dynamynd/user");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var styled_components_1 = require("styled-components");

var enums_1 = require("../../enums");

var store_1 = require("../../store");

var index_1 = require("../index");

var purchasedProp = 'purchased';

var AvailableModules =
/** @class */
function (_super) {
  __extends(AvailableModules, _super);

  function AvailableModules() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.filters = [{
      name: 'Purchased',
      filterProp: purchasedProp,
      value: true
    }, {
      name: 'Locked',
      filterProp: purchasedProp,
      value: false
    }];
    _this.fadeDuration = 800;

    _this.renderAddOns = function (data) {
      if (!data.length) return react_1.default.createElement(react_native_1.Text, null, "No data.");
      var paymentsFeature = _this.props.paymentsFeature;
      return data.map(function (module, idx) {
        var description = module.description,
            title = module.title,
            purchased = module.purchased;

        var cardType = _this.getCardType(purchased, paymentsFeature);

        var pressHandler = paymentsFeature ? _this.cardPressHandler(module) : utilities_1.noop;
        return react_1.default.createElement(components_lib_1.Fade, {
          key: idx,
          duration: _this.fadeDuration,
          type: components_lib_1.Fade.type.fadeIn
        }, react_1.default.createElement(components_lib_1.Card, {
          pressHandler: pressHandler,
          title: title,
          content: description,
          type: cardType,
          renderActions: _this.renderActions(module, pressHandler)
        }));
      });
    };

    _this.getCardType = function (purchased, paymentsFeature) {
      if (!paymentsFeature) {
        return components_lib_1.Card.type.locked;
      }

      return purchased ? components_lib_1.Card.type.default : components_lib_1.Card.type.locked;
    };

    _this.renderActions = function (module, pressHandler) {
      return function () {
        var _a = _this.props,
            theme = _a.theme,
            paymentsFeature = _a.paymentsFeature;

        if (paymentsFeature) {
          return _this.renderPaymentsActions(module, pressHandler);
        }

        return react_1.default.createElement(components_lib_1.Button, {
          text: "Coming soon...",
          uppercase: true,
          type: components_lib_1.ButtonTypeEnum.link,
          onPress: utilities_1.noop,
          customColor: theme.colors.secondary.orange
        });
      };
    };

    _this.renderPaymentsActions = function (module, pressHandler) {
      var theme = _this.props.theme;
      var priceTag = module.priceTag,
          type = module.type,
          purchased = module.purchased;

      if (type === enums_1.ModuleTypeEnum.link) {
        return react_1.default.createElement(components_lib_1.Button, {
          text: "Read More",
          uppercase: true,
          type: components_lib_1.ButtonTypeEnum.link,
          onPress: pressHandler,
          customColor: theme.colors.secondary.orange,
          icon: {
            name: 'open-in-new',
            position: components_lib_1.ButtonIconPositionEnum.right,
            size: components_lib_1.IconSizeEnum.small,
            color: theme.colors.secondary.orange
          }
        });
      }

      if (purchased) {
        return react_1.default.createElement(components_lib_1.Button, {
          text: "See Results",
          uppercase: true,
          type: components_lib_1.ButtonTypeEnum.link,
          onPress: pressHandler,
          customColor: theme.colors.secondary.orange
        });
      }

      return react_1.default.createElement(components_lib_1.ButtonFixer, null, react_1.default.createElement(components_lib_1.Button, {
        text: "Unlock for " + priceTag,
        uppercase: true,
        type: components_lib_1.ButtonTypeEnum.secondary,
        size: components_lib_1.ButtonSizeEnum.small,
        onPress: _this.cardPressHandler(module)
      }));
    };

    _this.cardPressHandler = function (module) {
      return function () {
        var type = module.type,
            purchased = module.purchased;

        if (type === enums_1.ModuleTypeEnum.link) {
          _this.handleLinkAddOnPress(module.link);

          return;
        }

        if (purchased) {
          // the order of actions is important - in AddOn module screen when changing the query string param
          // no event is triggered, so we need to rely on tracking the updates of the activeModule.
          _this.props.navigateTo(routes_1.RoutesKeysEnum.moduleView, {
            module: module.outputGroupId
          });

          _this.props.setActiveModule(module.name);

          return;
        }

        var _a = _this.props,
            showPaymentModal = _a.showPaymentModal,
            personId = _a.personId;
        var purchaseData = {
          personId: personId,
          outputGroupId: module.outputGroupId
        }; // opens the overlay with the purchase module view

        showPaymentModal(content_1.ContentPagesEnum.payment, purchaseData, _this.purchaseCloseActionHandler);
      };
    };

    _this.handleLinkAddOnPress = function (link) {
      if (react_native_1.Platform.OS === 'web') {
        window.open(_this.prepareLink(link), '_blank');
      }
    };

    _this.prepareLink = function (link) {
      var test = /^http/g;

      if (link.search(test) < 0) {
        return "https://" + link;
      }

      return link;
    };

    _this.purchaseCloseActionHandler = function (moduleId) {
      _this.props.getAvailableModulesWithPurchase(moduleId);
    };

    return _this;
  }

  AvailableModules.prototype.render = function () {
    var _a = this.props,
        label = _a.label,
        data = _a.data,
        updatingAvailableModules = _a.updatingAvailableModules,
        displayFilters = _a.displayFilters,
        paymentsFeature = _a.paymentsFeature;
    return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(react_1.default.Fragment, null, label && react_1.default.createElement(components_lib_1.Label, {
      uppercase: true
    }, label), updatingAvailableModules ? react_1.default.createElement(index_1.LoadingIndicatorModules, null) : react_1.default.createElement(components_lib_1.WithBoolFilters, {
      displayFilters: paymentsFeature && displayFilters,
      filters: this.filters,
      data: data,
      renderFiltered: this.renderAddOns
    })));
  };

  return AvailableModules;
}(react_1.PureComponent);

var mapStateToProps = function mapStateToProps(state) {
  return {
    updatingAvailableModules: store_1.updatingAvailableModulesSelector(state),
    personId: user_1.userIdSelector(state),
    paymentsFeature: features_1.paymentsFeatureSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    getAvailableModulesWithPurchase: redux_1.bindActionCreators(store_1.getAvailableModulesWithPurchaseActionCreator, dispatch),
    setActiveModule: redux_1.bindActionCreators(store_1.setActiveModuleActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, styled_components_1.withTheme, core_1.withNavigateTo);
exports.default = hoc(AvailableModules);