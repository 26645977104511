"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ValidationErrorMessagesEnum;

(function (ValidationErrorMessagesEnum) {
  ValidationErrorMessagesEnum["avatar"] = "Please select an avatar";
  ValidationErrorMessagesEnum["firstName"] = "Please enter your first name";
  ValidationErrorMessagesEnum["lastName"] = "Please enter your last name";
  ValidationErrorMessagesEnum["fullName"] = "Please enter your name";
  ValidationErrorMessagesEnum["nameLengthShort"] = "Name must be at least 2 characters";
  ValidationErrorMessagesEnum["nameLengthLong"] = "Name must be no more than 30 characters";
  ValidationErrorMessagesEnum["dateOfBirthMinAge"] = "Minimum age is 13 years";
  ValidationErrorMessagesEnum["dateOfBirthRequired"] = "Birthdate is required";
  ValidationErrorMessagesEnum["email"] = "Please enter your email";
  ValidationErrorMessagesEnum["emailConfirm"] = "Emails must match";
  ValidationErrorMessagesEnum["emailValid"] = "Please enter a valid email";
  ValidationErrorMessagesEnum["emailRegistered"] = "The email is already registered";
  ValidationErrorMessagesEnum["passwordRequired"] = "Password is required";
  ValidationErrorMessagesEnum["confirmPasswordRequired"] = "Confirm Password is required";
  ValidationErrorMessagesEnum["passwordLength"] = "Password must be at least 8 characters";
  ValidationErrorMessagesEnum["passwordShouldMatch"] = "Passwords must match";
  ValidationErrorMessagesEnum["phoneNumber"] = "Phone number is not valid";
  ValidationErrorMessagesEnum["zipCodeNotValid"] = "Zip code is not valid";
  ValidationErrorMessagesEnum["phoneNumberConfirm"] = "Phone numbers must match";
  ValidationErrorMessagesEnum["phoneNumberRequired"] = "Cell phone number is required";
  ValidationErrorMessagesEnum["phoneRegistered"] = "The cell phone is already registered";
  ValidationErrorMessagesEnum["termsAccepted"] = "Please review and accept the Terms & Conditions to proceed";
  ValidationErrorMessagesEnum["subject"] = "Please select the subject of your inquiry";
  ValidationErrorMessagesEnum["bodyShort"] = "Message must be at least 8 characters long";
  ValidationErrorMessagesEnum["bodyLong"] = "The message must be of maximum 1000 characters long";
  ValidationErrorMessagesEnum["bodyRequired"] = "Please enter a message";
  ValidationErrorMessagesEnum["requiredField"] = "This field is required.";
  ValidationErrorMessagesEnum["accessCodeMin"] = "Access Code must be at least 2 characters.";
  ValidationErrorMessagesEnum["accessCodeMax"] = "Access Code must be no more than 100 characters.";
  ValidationErrorMessagesEnum["resetPasswordCodeLength"] = "Provide the six digits code.";
  ValidationErrorMessagesEnum["invalidUsername"] = "Invalid Username value.";
})(ValidationErrorMessagesEnum = exports.ValidationErrorMessagesEnum || (exports.ValidationErrorMessagesEnum = {}));