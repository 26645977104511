"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FieldLabels;

(function (FieldLabels) {
  FieldLabels["avatar"] = "Choose Avatar";
  FieldLabels["firstName"] = "First Name";
  FieldLabels["lastName"] = "Last Name";
  FieldLabels["nickName"] = "Nickname (Optional)";
  FieldLabels["loginType"] = "Sign In Method";
  FieldLabels["nickname"] = "Nickname";
  FieldLabels["dateOfBirth"] = "Birthdate";
  FieldLabels["email"] = "Your Username/Email";
  FieldLabels["emailConfirm"] = "Confirm Email";
  FieldLabels["phoneNumber"] = "Phone Number";
  FieldLabels["phoneNumberConfirm"] = "Confirm Phone Number";
  FieldLabels["password"] = "Password ";
  FieldLabels["passwordConfirm"] = "Confirm Password";
  FieldLabels["accessCode"] = "Access Code";
  FieldLabels["continue"] = "Continue";
  FieldLabels["back"] = "Back";
  FieldLabels["signUp"] = "Create profile";
  FieldLabels["forgotPasswordLink"] = "Forgot your password?";
  FieldLabels["forgotPasswordUsername"] = "Email or Phone Number";
  FieldLabels["forgotPasswordCode"] = "Validation Code";
  FieldLabels["forgotPasswordPasswordLabel"] = "New Password";
  FieldLabels["forgotPasswordConfirmPasswordLabel"] = "Confirm New Password";
  FieldLabels["send"] = "Send";
  FieldLabels["forgotPasswordResendCode"] = "Resend Code";
  FieldLabels["country"] = "Country";
  FieldLabels["zipCode"] = "Zip Code";
})(FieldLabels = exports.FieldLabels || (exports.FieldLabels = {}));