"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var ModulesContentPagesEnum;

(function (ModulesContentPagesEnum) {
  ModulesContentPagesEnum["introSection"] = "introSection";
  ModulesContentPagesEnum["overviewSection"] = "overviewSection";
  ModulesContentPagesEnum["infoSection"] = "infoSection";
  ModulesContentPagesEnum["summarySection"] = "summarySection";
  ModulesContentPagesEnum["moduleLabels"] = "moduleLabels";
  ModulesContentPagesEnum["tubesRightHeader"] = "tubesRightHeader";
  ModulesContentPagesEnum["tubesLeftTop"] = "tubesLeftTop";
  ModulesContentPagesEnum["tubesLeftMid"] = "tubesLeftMid";
  ModulesContentPagesEnum["tubesLeftBottom"] = "tubesLeftBottom";
})(ModulesContentPagesEnum = exports.ModulesContentPagesEnum || (exports.ModulesContentPagesEnum = {}));