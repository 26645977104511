"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.initialUserState = {
  profile: {
    ID: '',
    Avatar: '',
    CellPhone: '',
    BirthMonth: '',
    Telephone: '',
    SpecialNeeds: '',
    Address1: '',
    Address2: '',
    BirthYear: '',
    InitiatesIn: '',
    ReferralSource: '',
    Status: '',
    MO: '',
    State: '',
    FirstName: '',
    PersonType: '',
    PersonId: '',
    City: '',
    Username: '',
    Education: '',
    Email: '',
    EmploymentStatus: '',
    Country: '',
    Zip: '',
    LastName: '',
    Nickname: '',
    NaturalAdvantage: '',
    FullName: '',
    Gender: '',
    Ethnicity: '',
    DOB: '',
    EducationGoal: '',
    Title: '',
    OrigAccessCode: '',
    PurchasedModules: []
  },
  view: {
    fetching: false
  }
};