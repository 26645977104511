"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var react_1 = __importStar(require("react"));

var react_native_1 = require("react-native");

var react_native_popup_menu_1 = require("react-native-popup-menu");

var SlideInMenuTop_1 = require("./SlideInMenuTop");

var styles_1 = require("./styles");

var ContextMenu = react_native_popup_menu_1.renderers.ContextMenu,
    Popover = react_native_popup_menu_1.renderers.Popover,
    SlideInMenu = react_native_popup_menu_1.renderers.SlideInMenu;
var renderers = {
  ContextMenu: ContextMenu,
  Popover: Popover,
  SlideInMenuTop: SlideInMenuTop_1.SlideInMenuTop,
  SlideInMenuBottom: SlideInMenu
};
var RenderersEnum;

(function (RenderersEnum) {
  RenderersEnum["ContextMenu"] = "ContextMenu";
  RenderersEnum["Popover"] = "Popover";
  RenderersEnum["SlideInMenuBottom"] = "SlideInMenu";
  RenderersEnum["SlideInMenuTop"] = "SlideInMenuTop";
})(RenderersEnum || (RenderersEnum = {}));

var Popup =
/** @class */
function (_super) {
  __extends(Popup, _super);

  function Popup() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.onMenuSelect = function (option) {
      var onSelect = _this.props.onSelect;

      if (typeof onSelect === 'function') {
        onSelect(option);
      }
    };

    return _this;
  }

  Popup.prototype.renderOptions = function () {
    var options = this.props.options;
    return options.map(function (_a, idx) {
      var disabled = _a.disabled,
          onSelect = _a.onSelect,
          render = _a.render;
      return react_1.default.createElement(react_native_popup_menu_1.MenuOption, {
        disabled: disabled,
        onSelect: onSelect,
        key: idx
      }, render());
    });
  };

  Popup.prototype.render = function () {
    var _a = this.props,
        rendererProp = _a.renderer,
        renderTrigger = _a.renderTrigger,
        disabled = _a.disabled,
        optionsCustomStyles = _a.optionsCustomStyles,
        triggerCustomStyles = _a.triggerCustomStyles,
        rendererPropsCustom = _a.rendererProps;
    var renderer = renderers[rendererProp];
    var triggerStyles = {};
    var rendererProps = {};
    var menuOptions = {};

    if (optionsCustomStyles) {
      menuOptions.customStyles = optionsCustomStyles;
    }

    if (triggerStyles) {
      triggerStyles = __assign(__assign({}, triggerCustomStyles), {
        triggerDefaultStyles: styles_1.triggerDefaultStyles
      });
    }

    if (rendererProps) {
      rendererProps = __assign({}, rendererPropsCustom);
    }

    return react_1.default.createElement(react_native_1.View, null, react_1.default.createElement(react_native_popup_menu_1.Menu, {
      renderer: renderer,
      onSelect: this.onMenuSelect,
      rendererProps: rendererProps
    }, react_1.default.createElement(react_native_popup_menu_1.MenuTrigger, {
      customStyles: triggerStyles,
      disabled: disabled
    }, renderTrigger()), react_1.default.createElement(react_native_popup_menu_1.MenuOptions, __assign({}, menuOptions), this.renderOptions())));
  };

  Popup.Renderers = RenderersEnum;
  return Popup;
}(react_1.Component);

exports.Popup = Popup;