"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var responsive_1 = require("@dynamynd/responsive");

var routes_1 = require("@dynamynd/routes");

var sharing_1 = require("@dynamynd/sharing");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var __1 = require("..");

var components_1 = require("../../components");

var enums_1 = require("../../enums");

var store_1 = require("../../store");

var Registration =
/** @class */
function (_super) {
  __extends(Registration, _super);

  function Registration(props) {
    var _this = _super.call(this, props) || this;

    _this.state = {
      loginType: '',
      isUnder13: true,
      pageTitle: undefined,
      dob: ''
    };

    _this.setInitialFormValues = function () {
      return {
        dateOfBirth: '',
        loginType: _this.state.loginType,
        firstName: '',
        lastName: '',
        nickName: '',
        email: '',
        emailConfirm: '',
        emailValid: true,
        phoneValid: true,
        phoneNumber: '',
        phoneNumberConfirm: '',
        password: '',
        passwordConfirm: '',
        accessCode: '',
        termsAccepted: false,
        avatar: '',
        country: '',
        zipCode: ''
      };
    };

    _this.setDOB = function (dob) {
      _this.setState({
        dob: dob
      });
    };

    _this.setIsUnder13 = function (b) {
      _this.setState({
        isUnder13: b
      });
    };

    _this.setLoginType = function (value) {
      _this.setState({
        loginType: value
      });
    };

    _this.setLoginTypePhone = function () {
      _this.setLoginType(enums_1.LoginTypeEnum.phone);
    };

    _this.setLoginTypeEmail = function () {
      _this.setLoginType(enums_1.LoginTypeEnum.username);
    };

    _this.setPageTitle = function (pageTitle) {
      _this.setState({
        pageTitle: pageTitle
      });
    };

    _this.setTitle = function (activeStep) {
      return ['How do you prefer to register?', 'Let’s know each other'][activeStep];
    };

    _this.onSubmit = function (values) {
      // if access code has been set to the url, save it to the users profile
      var accessCode = utilities_1.getAccessCodeValue(_this.props.navigation.getParam);
      values.accessCode = accessCode !== null && accessCode !== void 0 ? accessCode : '';

      _this.props.registerUser(values);
    };

    _this.scrollTo = react_1.default.createRef();
    return _this;
  }

  Registration.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        avatars = _a.avatars,
        screenProps = _a.screenProps,
        navigation = _a.navigation;
    var WebViewComponent = screenProps.components.WebViewComponent;
    var loginType = this.state.loginType;
    var initialValues = this.setInitialFormValues();
    var navigationParams = utilities_1.navigateWithAccessCodeQueryParam(navigation.getParam);
    return react_1.default.createElement(responsive_1.ResponsiveContext.Consumer, null, function (_a) {
      var activeBreakpoint = _a.activeBreakpoint;
      return react_1.default.createElement(content_1.WithAdditionalContent, {
        WebViewComponent: WebViewComponent,
        includeFooter: true,
        navigationExtraParams: navigationParams
      }, function (_a) {
        var showContent = _a.showContent;
        var small = responsive_1.isSmall(activeBreakpoint);
        return react_1.default.createElement(components_lib_1.ColumnCentered, null, react_1.default.createElement(components_lib_1.Header, null, react_1.default.createElement(components_lib_1.LogoMascot, {
          activeBreakpoint: activeBreakpoint,
          color: "purple",
          version: {
            sm: 'image',
            lg: 'image'
          },
          navigationExtraParams: navigationParams
        }), !small && react_1.default.createElement(components_lib_1.Button, {
          type: components_lib_1.ButtonTypeEnum.link,
          onPress: function onPress() {
            return navigation.navigate(routes_1.RoutesKeysEnum.login, navigationParams);
          },
          text: "Log In",
          uppercase: true
        }), small && react_1.default.createElement(components_lib_1.ContentItemsPopup, {
          navigation: navigation,
          showPrivacy: function showPrivacy() {
            return showContent(content_1.ContentPagesEnum.privacy);
          },
          showTerms: function showTerms() {
            return showContent(content_1.ContentPagesEnum.terms);
          },
          disableRegister: true
        })), react_1.default.createElement(components_lib_1.ContentContainerScrollable, {
          scroll: function scroll(view) {
            return _this.scrollTo = view;
          }
        }, react_1.default.createElement(components_lib_1.Wizard, null, function (previousHandler, nextHandler, activeStep) {
          return react_1.default.createElement(components_lib_1.ColumnCentered, null, react_1.default.createElement(components_1.TopLabel, null, "Create Account"), react_1.default.createElement(components_1.TitleWrapper, {
            activeBreakpoint: activeBreakpoint
          }, react_1.default.createElement(components_lib_1.Title, {
            activeBreakpoint: activeBreakpoint,
            centered: true
          }, _this.state.isUnder13 ? _this.state.pageTitle : _this.setTitle(activeStep))), _this.state.isUnder13 ? react_1.default.createElement(__1.Under13Workflow, {
            setPageTitle: _this.setPageTitle,
            activeBreakpoint: activeBreakpoint,
            screenProps: screenProps,
            // user is under 13 and has completed under13 workflow
            onWorkflowSuccess: _this.onSubmit,
            // user is OVER 13
            onWorkflowExit: function onWorkflowExit(dob) {
              // quick and dirty way to stash the dob
              _this.setDOB(dob); // quick and dirty way to swap views


              _this.setIsUnder13(false);
            }
          }) : react_1.default.createElement(components_lib_1.Steps, {
            activeStep: activeStep
          }, react_1.default.createElement(components_1.TileGroupRow, {
            activeBreakpoint: activeBreakpoint
          }, react_1.default.createElement(components_lib_1.RowWrapper, {
            wrap: true,
            activeBreakpoint: activeBreakpoint
          }, react_1.default.createElement(components_lib_1.TileButton, {
            activeBreakpoint: activeBreakpoint,
            text: enums_1.FieldPlaceHolders.signUpWithPhone,
            isSelected: loginType === enums_1.LoginTypeEnum.phone,
            action: _this.setLoginTypePhone
          }), react_1.default.createElement(components_lib_1.TileButton, {
            activeBreakpoint: activeBreakpoint,
            text: enums_1.FieldPlaceHolders.signUpWithEmail,
            isSelected: loginType === enums_1.LoginTypeEnum.username,
            action: _this.setLoginTypeEmail
          }))), react_1.default.createElement(react_1.Fragment, null)), loginType === enums_1.LoginTypeEnum.username && react_1.default.createElement(components_1.EmailRegistrationForm, {
            activeBreakpoint: activeBreakpoint,
            initialValues: __assign(__assign({}, initialValues), {
              dateOfBirth: _this.state.dob
            }),
            navigation: navigation,
            avatars: avatars,
            previousHandler: previousHandler,
            nextHandler: nextHandler,
            activeStep: activeStep,
            screenProps: screenProps,
            showTerms: function showTerms() {
              return showContent(content_1.ContentPagesEnum.terms);
            },
            onSubmit: _this.onSubmit
          }), loginType === enums_1.LoginTypeEnum.phone && react_1.default.createElement(components_1.PhoneRegistrationForm, {
            activeBreakpoint: activeBreakpoint,
            initialValues: initialValues,
            navigation: navigation,
            avatars: avatars,
            previousHandler: previousHandler,
            nextHandler: nextHandler,
            activeStep: activeStep,
            screenProps: screenProps,
            showTerms: function showTerms() {
              return showContent(content_1.ContentPagesEnum.terms);
            },
            onSubmit: _this.onSubmit
          }));
        })));
      });
    });
  };

  return Registration;
}(react_1.Component);

exports.Registration = Registration;

var mapStateToProps = function mapStateToProps(state) {
  return {
    avatars: content_1.avatarsRandomizedSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    registerUser: redux_1.bindActionCreators(store_1.registerSubmitActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, sharing_1.withAddThisSharing);
exports.default = hoc(Registration);