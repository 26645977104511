"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var components_1 = require("../../components");

var enums_1 = require("../../enums");

var store_1 = require("../../store");

var SharedAddOnModuleScreen =
/** @class */
function (_super) {
  __extends(SharedAddOnModuleScreen, _super);

  function SharedAddOnModuleScreen() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.numberOfTipsToRender = 'all';
    _this.summarySectionTitle = 'Up Your Game';

    _this.renderIntroSection = function () {
      var _a = _this.props,
          _b = _a.sharedModuleData,
          module = _b.module,
          person = _b.person,
          scrollToNextSection = _a.scrollToNextSection;
      var introSection = module.introSection,
          introImageUri = module.introImageUri;
      var personFullName = person.firstName + " " + person.lastName;
      return react_1.default.createElement(components_1.SectionIntro, {
        title: personFullName,
        content: introSection,
        scrollToElementHandler: scrollToNextSection,
        introImageUri: introImageUri
      });
    };

    _this.renderContent = function () {
      var _a = _this.props,
          module = _a.sharedModuleData.module,
          scrollToNextSection = _a.scrollToNextSection,
          setSectionHeightState = _a.setSectionHeightState,
          renderModules = _a.renderModules;
      return renderModules({
        setSectionHeightState: setSectionHeightState,
        resultsModuleName: '',
        moduleData: module,
        summarySectionTitle: _this.summarySectionTitle,
        numberOfTipsToRender: _this.numberOfTipsToRender,
        showPaymentModal: function showPaymentModal() {
          return null;
        },
        scrollToSectionHandler: scrollToNextSection,
        withCustomAnalytics: false,
        withAvailableModules: false
      });
    };

    return _this;
  }

  SharedAddOnModuleScreen.prototype.componentWillUnmount = function () {
    this.props.setSharedModuleErrorState({
      error: false
    });
  };

  SharedAddOnModuleScreen.prototype.render = function () {
    var _a = this.props,
        setSectionHeightState = _a.setSectionHeightState,
        errorState = _a.errorState;
    return react_1.default.createElement(react_1.default.Fragment, null, react_1.default.createElement(components_lib_1.ModulesBackground, {
      shouldExpand: errorState,
      onLayoutHandler: function onLayoutHandler(event) {
        var introHeight = event.nativeEvent.layout.height;
        setSectionHeightState(enums_1.ModuleSectionViewsEnum.intro, introHeight);
      }
    }, react_1.default.createElement(components_lib_1.Header, null, react_1.default.createElement(components_lib_1.LogoMascot, {
      color: "purple",
      version: {
        sm: 'image',
        lg: 'image'
      }
    })), !errorState && this.renderIntroSection(), errorState && react_1.default.createElement(components_1.SharedModuleNotPurchased, null)), !errorState && this.renderContent());
  };

  return SharedAddOnModuleScreen;
}(react_1.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    errorState: store_1.sharedModuleErrorStateSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    setSharedModuleErrorState: redux_1.bindActionCreators(store_1.setSharedModuleErrorStateActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, components_1.withSectionsHeight, components_1.withSectionModulesRender, components_1.withSharedModuleData);
exports.default = hoc(SharedAddOnModuleScreen);