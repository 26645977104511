"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var RoutesKeysEnum;

(function (RoutesKeysEnum) {
  RoutesKeysEnum["bootstrap"] = "bootstrap";
  RoutesKeysEnum["home"] = "home";
  RoutesKeysEnum["auth"] = "auth";
  RoutesKeysEnum["dynamynd"] = "dynamynd";
  RoutesKeysEnum["shared"] = "shared";
  RoutesKeysEnum["sharedModule"] = "module";
  RoutesKeysEnum["sharedResultsModule"] = "result";
  RoutesKeysEnum["terms"] = "terms";
  RoutesKeysEnum["login"] = "login";
  RoutesKeysEnum["signup"] = "signup";
  RoutesKeysEnum["passwordReset"] = "passwordReset";
  RoutesKeysEnum["error"] = "error";
  RoutesKeysEnum["survey"] = "survey";
  RoutesKeysEnum["user"] = "user";
  RoutesKeysEnum["modules"] = "modules";
  RoutesKeysEnum["editUserProfile"] = "edit";
  RoutesKeysEnum["take"] = "take";
  RoutesKeysEnum["results"] = "results";
  RoutesKeysEnum["dashboard"] = "dashboard";
  RoutesKeysEnum["moduleView"] = "view";
  RoutesKeysEnum["contact"] = "contact";
  RoutesKeysEnum["purchaseModule"] = "purchase";
  RoutesKeysEnum["faq"] = "faq";
})(RoutesKeysEnum = exports.RoutesKeysEnum || (exports.RoutesKeysEnum = {}));