import {IDynamyndState, WithPopupLayout, withPurchasedValidation, withScoringValidation} from '@dynamynd/dynamynd';
import {FeaturesEnum, withFeature} from '@dynamynd/features';
import {
    AddOnModuleScreen,
    PurchaseModuleScreen,
    TAddOnModuleScreenProps,
    TPurchaseModuleScreenProps
} from '@dynamynd/modules';
import {RoutesKeysEnum} from '@dynamynd/routes';
import {withRefetchUserProfile} from '@dynamynd/user';
import {createNavigator, SwitchRouter} from '@react-navigation/core';
import {compose} from 'redux';
import {appView} from '../core/AppView/AppView';
import {withAnalyticsTrackerWeb} from '../core/withAnalyticsTrackerWeb';

const EnhancedAddOnModuleScreen = compose(
    withAnalyticsTrackerWeb,
    withFeature<TAddOnModuleScreenProps, IDynamyndState>(FeaturesEnum.payments, RoutesKeysEnum.dashboard),
    withRefetchUserProfile,
    withScoringValidation,
    withPurchasedValidation
)(AddOnModuleScreen);

const EnhancedPurchaseModuleScreen = compose(
    withAnalyticsTrackerWeb,
    withFeature<TPurchaseModuleScreenProps, IDynamyndState>(FeaturesEnum.payments, RoutesKeysEnum.dashboard)
)(PurchaseModuleScreen);

export const modulesNavigator = createNavigator(
    appView(WithPopupLayout),
    SwitchRouter({
        [RoutesKeysEnum.moduleView]: EnhancedAddOnModuleScreen,
        [RoutesKeysEnum.purchaseModule]: EnhancedPurchaseModuleScreen
    }),
    {}
);
