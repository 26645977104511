"use strict";

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var core_1 = require("@dynamynd/core");

var utilities_1 = require("@dynamynd/utilities");

var Yup = __importStar(require("yup"));

var regEx_1 = require("./regEx");

var dateDisplayFormat = 'MM/DD/YYYY';
var minAgeYears = 13;
exports.registrationValidationSchema = Yup.object().shape({
  firstName: Yup.string().min(2, core_1.ValidationErrorMessagesEnum.nameLengthShort).max(30, core_1.ValidationErrorMessagesEnum.nameLengthLong).required(core_1.ValidationErrorMessagesEnum.firstName),
  lastName: Yup.string().min(2, core_1.ValidationErrorMessagesEnum.nameLengthShort).max(30, core_1.ValidationErrorMessagesEnum.nameLengthLong).required(core_1.ValidationErrorMessagesEnum.lastName),
  nickName: Yup.string().min(2, core_1.ValidationErrorMessagesEnum.nameLengthShort).max(30, core_1.ValidationErrorMessagesEnum.nameLengthLong),
  dateOfBirth: Yup.string().test('dateOfBirth', core_1.ValidationErrorMessagesEnum.dateOfBirthRequired, function (value) {
    return utilities_1.isDateValid(value, dateDisplayFormat);
  }).test('dateOfBirth', core_1.ValidationErrorMessagesEnum.dateOfBirthMinAge, function (value) {
    return utilities_1.diffFromNow(value, dateDisplayFormat, 'years') >= minAgeYears;
  }),
  email: Yup.string().when('loginType', {
    is: 'username',
    then: Yup.string().matches(regEx_1.emailRegEx, core_1.ValidationErrorMessagesEnum.emailValid).min(2).required(core_1.ValidationErrorMessagesEnum.email)
  }),
  emailConfirm: Yup.string().when('loginType', {
    is: 'username',
    then: Yup.string().required(core_1.ValidationErrorMessagesEnum.email).oneOf([Yup.ref('email')], core_1.ValidationErrorMessagesEnum.emailConfirm)
  }),
  emailValid: Yup.boolean().when('loginType', {
    is: 'username',
    then: Yup.boolean().required().oneOf([true], core_1.ValidationErrorMessagesEnum.emailRegistered)
  }),
  password: Yup.string().required(core_1.ValidationErrorMessagesEnum.passwordRequired).min(8, core_1.ValidationErrorMessagesEnum.passwordLength),
  phoneNumber: Yup.string().when('loginType', {
    is: 'phone',
    then: Yup.string().matches(regEx_1.phoneNumberRegEx, core_1.ValidationErrorMessagesEnum.phoneNumber).required(core_1.ValidationErrorMessagesEnum.phoneNumberRequired)
  }),
  phoneNumberConfirm: Yup.string().when('loginType', {
    is: 'phone',
    then: Yup.string().required(core_1.ValidationErrorMessagesEnum.phoneNumberRequired).oneOf([Yup.ref('phoneNumber')], core_1.ValidationErrorMessagesEnum.phoneNumberConfirm)
  }),
  phoneValid: Yup.boolean().when('loginType', {
    is: 'phone',
    then: Yup.boolean().required().oneOf([true], core_1.ValidationErrorMessagesEnum.phoneRegistered)
  }),
  accessCode: Yup.string(),
  loginType: Yup.string().required().oneOf(['username', 'phone']),
  termsAccepted: Yup.boolean().required().oneOf([true], core_1.ValidationErrorMessagesEnum.termsAccepted),
  country: Yup.string().required(core_1.ValidationErrorMessagesEnum.requiredField),
  zipCode: Yup.string().when('country', {
    is: core_1.usaFormFieldValue,
    then: Yup.string().required(core_1.ValidationErrorMessagesEnum.requiredField).max(5, core_1.ValidationErrorMessagesEnum.zipCodeNotValid).matches(regEx_1.usaZipCodeRegex, core_1.ValidationErrorMessagesEnum.zipCodeNotValid)
  })
});