"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

var _a;

Object.defineProperty(exports, "__esModule", {
  value: true
});

var components_lib_1 = require("@dynamynd/components-lib");

var content_1 = require("@dynamynd/content");

var core_1 = require("@dynamynd/core");

var responsive_1 = require("@dynamynd/responsive");

var routes_1 = require("@dynamynd/routes");

var sharing_1 = require("@dynamynd/sharing");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var react_redux_1 = require("react-redux");

var redux_1 = require("redux");

var components_1 = require("../../components");

var enums_1 = require("../../enums");

var store_1 = require("../../store");

var components_2 = require("./components");

var functions_1 = require("./functions");

var formComponentMap = (_a = {}, _a[enums_1.PasswordResetScreenStageEnum.requestLink] = components_2.RequestLinkForm, _a[enums_1.PasswordResetScreenStageEnum.updatePassword] = components_2.UpdatePasswordForm, _a);

var PasswordResetScreen =
/** @class */
function (_super) {
  __extends(PasswordResetScreen, _super);

  function PasswordResetScreen() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.pageTitle = 'Password Reset';

    _this.renderContent = function () {
      var _a = _this.props,
          stage = _a.passwordResetStateModel.stage,
          updating = _a.updating;
      var FormComponent = formComponentMap[stage];
      if (!FormComponent) return null;
      return react_1.default.createElement(FormComponent, {
        onSubmit: _this.formSubmitHandler,
        updating: updating
      });
    }; // todo values type


    _this.formSubmitHandler = function (values) {
      var _a;

      var _b = _this.props,
          _c = _b.passwordResetStateModel,
          stage = _c.stage,
          username = _c.username,
          initiateResetPasswordFlow = _b.initiateResetPasswordFlow,
          updateUserPassword = _b.updateUserPassword,
          navigateTo = _b.navigateTo;
      var actionMap = (_a = {}, _a[enums_1.PasswordResetScreenStageEnum.requestLink] = initiateResetPasswordFlow, _a[enums_1.PasswordResetScreenStageEnum.updatePassword] = updateUserPassword, _a);
      var action = actionMap[stage];

      if (!action) {
        navigateTo(routes_1.RoutesKeysEnum.error, {});
      }

      if (stage === enums_1.PasswordResetScreenStageEnum.requestLink) {
        var payload = functions_1.validateAndPrepareResetPasswordModel(values.username);

        if (!payload) {
          // todo toast
          return;
        }

        initiateResetPasswordFlow(payload);
        return;
      }

      var password = values.password,
          passwordConfirm = values.passwordConfirm,
          code = values.code;
      updateUserPassword({
        Username: username,
        resetPin: code,
        Password: password,
        Password2: passwordConfirm
      });
    };

    return _this;
  }

  PasswordResetScreen.prototype.componentDidMount = function () {
    this.props.updateResetPasswordStage({
      stage: enums_1.PasswordResetScreenStageEnum.requestLink
    });
  };

  PasswordResetScreen.prototype.componentWillUnmount = function () {
    this.props.setInitialValuesToResetPasswordViewState();
  };

  PasswordResetScreen.prototype.render = function () {
    var _this = this;

    var _a = this.props,
        activeBreakpoint = _a.activeBreakpoint,
        updating = _a.updating,
        navigateTo = _a.navigateTo,
        navigation = _a.navigation,
        WebViewComponent = _a.screenProps.components.WebViewComponent;
    var small = responsive_1.isSmall(activeBreakpoint);
    var navigationParams = utilities_1.navigateWithAccessCodeQueryParam(navigation.getParam);
    return react_1.default.createElement(content_1.WithAdditionalContent, {
      WebViewComponent: WebViewComponent,
      includeFooter: true,
      navigationExtraParams: navigationParams
    }, function (_a) {
      var showContent = _a.showContent;
      return react_1.default.createElement(components_lib_1.WithTouchDisabled, {
        disabled: updating
      }, react_1.default.createElement(components_lib_1.ColumnCentered, null, react_1.default.createElement(components_lib_1.Header, null, react_1.default.createElement(components_lib_1.LogoMascot, {
        activeBreakpoint: activeBreakpoint,
        color: "purple",
        version: {
          sm: 'image',
          lg: 'image'
        },
        navigationExtraParams: navigationParams
      }), !small && react_1.default.createElement(components_lib_1.Row, null, react_1.default.createElement(components_1.WithRightMargin, null, react_1.default.createElement(components_lib_1.Button, {
        type: components_lib_1.ButtonTypeEnum.link,
        onPress: function onPress() {
          return navigateTo(routes_1.RoutesKeysEnum.login, navigationParams);
        },
        text: "Log In",
        uppercase: true
      }))), small && react_1.default.createElement(components_lib_1.ContentItemsPopup, {
        navigation: navigation,
        showPrivacy: function showPrivacy() {
          return showContent(content_1.ContentPagesEnum.privacy);
        },
        showTerms: function showTerms() {
          return showContent(content_1.ContentPagesEnum.terms);
        },
        disableRegister: true
      })), react_1.default.createElement(components_lib_1.ColumnCentered, null, react_1.default.createElement(components_lib_1.TitleWithMargin, null, _this.pageTitle), _this.renderContent())));
    });
  };

  return PasswordResetScreen;
}(react_1.Component);

var mapStateToProps = function mapStateToProps(state) {
  return {
    updating: store_1.authUpdatingSelector(state),
    passwordResetStateModel: store_1.authPasswordResetModelSelector(state)
  };
};

var mapDispatchToProps = function mapDispatchToProps(dispatch) {
  return {
    initiateResetPasswordFlow: redux_1.bindActionCreators(store_1.initiateResetPasswordFlowActionCreator, dispatch),
    updateUserPassword: redux_1.bindActionCreators(store_1.updateUserPasswordActionCreator, dispatch),
    updateResetPasswordStage: redux_1.bindActionCreators(store_1.updateResetPasswordStageActionActionCreator, dispatch),
    setInitialValuesToResetPasswordViewState: redux_1.bindActionCreators(store_1.setInitialValuesToResetPasswordViewStateActionCreator, dispatch)
  };
};

var connector = react_redux_1.connect(mapStateToProps, mapDispatchToProps);
var hoc = redux_1.compose(connector, core_1.withNavigateTo, responsive_1.withResponsiveMeta, sharing_1.withAddThisSharing);
exports.default = hoc(PasswordResetScreen);