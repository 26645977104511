"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FieldNames;

(function (FieldNames) {
  FieldNames["avatar"] = "avatar";
  FieldNames["userName"] = "userName";
  FieldNames["email"] = "email";
  FieldNames["phone"] = "phone";
  FieldNames["firstName"] = "firstName";
  FieldNames["lastName"] = "lastName";
  FieldNames["nickName"] = "nickName";
  FieldNames["country"] = "country";
  FieldNames["zipCode"] = "zipCode";
  FieldNames["gender"] = "gender";
  FieldNames["ethnicity"] = "ethnicity";
  FieldNames["specialNeeds"] = "specialNeeds";
  FieldNames["educationalGoal"] = "educationalGoal";
  FieldNames["currentPassword"] = "currentPassword";
  FieldNames["password"] = "password";
  FieldNames["confirmPassword"] = "confirmPassword";
})(FieldNames = exports.FieldNames || (exports.FieldNames = {}));