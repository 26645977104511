"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
var FieldPlaceHolders;

(function (FieldPlaceHolders) {
  FieldPlaceHolders["userName"] = "Username";
  FieldPlaceHolders["firstName"] = "First Name";
  FieldPlaceHolders["lastName"] = "Last Name";
  FieldPlaceHolders["lastInitial"] = "Last Initial";
  FieldPlaceHolders["nickName"] = "Nickname";
  FieldPlaceHolders["loginType"] = "Sign In Method";
  FieldPlaceHolders["dateOfBirth"] = "MM/DD/YYYY";
  FieldPlaceHolders["email"] = "Email";
  FieldPlaceHolders["emailConfirm"] = "Email";
  FieldPlaceHolders["phoneNumber"] = "Phone Number";
  FieldPlaceHolders["phoneNumberConfirm"] = "Phone Number";
  FieldPlaceHolders["password"] = "Password ";
  FieldPlaceHolders["passwordConfirm"] = "Confirm Password";
  FieldPlaceHolders["accessCode"] = "Access Code";
  FieldPlaceHolders["signUp"] = "Create profile";
  FieldPlaceHolders["signIn"] = "LOG IN";
  FieldPlaceHolders["signUpWithEmail"] = "Sign up with email";
  FieldPlaceHolders["signUpWithPhone"] = "Sign up with phone number";
  FieldPlaceHolders["logInWithEmail"] = "Sign in with Username/Email";
  FieldPlaceHolders["logInWithPhone"] = "Sign in with phone number";
})(FieldPlaceHolders = exports.FieldPlaceHolders || (exports.FieldPlaceHolders = {}));