"use strict";

var __extends = this && this.__extends || function () {
  var _extendStatics = function extendStatics(d, b) {
    _extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) {
        if (b.hasOwnProperty(p)) d[p] = b[p];
      }
    };

    return _extendStatics(d, b);
  };

  return function (d, b) {
    _extendStatics(d, b);

    function __() {
      this.constructor = d;
    }

    d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
  };
}();

var __assign = this && this.__assign || function () {
  __assign = Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
      s = arguments[i];

      for (var p in s) {
        if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
      }
    }

    return t;
  };

  return __assign.apply(this, arguments);
};

var __importStar = this && this.__importStar || function (mod) {
  if (mod && mod.__esModule) return mod;
  var result = {};
  if (mod != null) for (var k in mod) {
    if (Object.hasOwnProperty.call(mod, k)) result[k] = mod[k];
  }
  result["default"] = mod;
  return result;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var content_parser_1 = require("@dynamynd/content-parser");

var utilities_1 = require("@dynamynd/utilities");

var react_1 = __importStar(require("react"));

var enums_1 = require("../../enums");

var services_1 = require("../../services");

var styled_1 = require("../styled");

var initialState = {
  least: '',
  most: ''
}; // todo refactor
// todo most/least label should come from props

var MostLeastSurveyQuestion =
/** @class */
function (_super) {
  __extends(MostLeastSurveyQuestion, _super);

  function MostLeastSurveyQuestion() {
    var _this = _super !== null && _super.apply(this, arguments) || this;

    _this.state = __assign({}, initialState); // todo refactor

    _this.pressLeastHandler = function (optionId) {
      return function () {
        var most = _this.state.most;

        if (_this.state.most === optionId) {
          most = '';

          _this.setState({
            most: most
          });
        }

        _this.setState({
          least: optionId
        });

        _this.updateResponseHandler({
          most: most,
          least: optionId
        });
      };
    }; // todo refactor


    _this.pressMostHandler = function (optionId) {
      return function () {
        // todo handle the case when there is already an answer and we need to deselect the option
        var least = _this.state.least;

        if (_this.state.least === optionId) {
          least = '';

          _this.setState({
            least: least
          });
        }

        _this.setState({
          most: optionId
        });

        _this.updateResponseHandler({
          least: least,
          most: optionId
        });
      };
    };

    return _this;
  }

  MostLeastSurveyQuestion.prototype.componentDidMount = function () {
    var _a = this.props,
        response = _a.response,
        options = _a.question.options;

    if (!utilities_1.isEmpty(response)) {
      this.syncResponseWithState(options, response);
    }
  };

  MostLeastSurveyQuestion.prototype.componentDidUpdate = function (prevProps) {
    this.propsUpdateHandler(prevProps);
  };

  MostLeastSurveyQuestion.prototype.propsUpdateHandler = function (prevProps) {
    var _a = this.props,
        _b = _a.response,
        currentResponseId = _b.ID,
        currentResponseValue = _b.Response,
        options = _a.question.options;
    var _c = prevProps.response,
        prevResponseId = _c.ID,
        prevResponseValue = _c.Response;

    if (utilities_1.isUndefined(currentResponseValue) && utilities_1.isUndefined(prevResponseValue)) {
      return;
    }

    if (utilities_1.isUndefined(currentResponseValue) && this.responsesHaveValues(this.state)) {
      this.setState(__assign({}, initialState));
      this.updateResponseHandler({
        least: '',
        most: ''
      });
      return;
    }

    if (currentResponseValue !== prevResponseValue || currentResponseId !== prevResponseId) {
      this.syncResponseWithState(options, this.props.response);
      return;
    }

    if (currentResponseValue === prevResponseValue && currentResponseId !== prevResponseId) {
      var _d = this.state,
          least = _d.least,
          most = _d.most;
      this.updateResponseHandler({
        least: least,
        most: most
      });
      return;
    }
  };

  MostLeastSurveyQuestion.prototype.responsesHaveValues = function (state) {
    var most = state.most,
        least = state.least;
    return most !== '' && least !== '';
  };

  MostLeastSurveyQuestion.prototype.syncResponseWithState = function (options, response) {
    var values = response.Response;
    if (!values) return;
    var answers = values.split(',');

    if (answers.length) {
      var most = services_1.surveyService.getOptionIdByValue(options, answers[0]);
      var least = services_1.surveyService.getOptionIdByValue(options, answers[1]);
      this.setState({
        most: most,
        least: least
      });
      this.updateResponseHandler({
        least: least,
        most: most
      });
    }
  };

  MostLeastSurveyQuestion.prototype.updateResponseHandler = function (answerOptionIds) {
    var state = {
      answerOptionIds: answerOptionIds,
      shouldRecordResponse: this.shouldRecordResponse({
        least: answerOptionIds.least,
        most: answerOptionIds.most
      }),
      type: enums_1.SurveyQuestionTypeEnum.mostLeast
    };
    this.props.updateResponse(state);
  };

  MostLeastSurveyQuestion.prototype.shouldRecordResponse = function (values) {
    var most = values.most,
        least = values.least;
    var _a = this.props,
        propsResponseValues = _a.response.Response,
        options = _a.question.options;
    var valuesAreDifferent = true;

    if (!utilities_1.isEmpty(propsResponseValues)) {
      var answers = propsResponseValues.split(',');

      if (answers.length) {
        var stateMost = services_1.surveyService.getOptionIdByValue(options, answers[0]);
        var stateLeast = services_1.surveyService.getOptionIdByValue(options, answers[1]);
        valuesAreDifferent = most !== stateMost || least !== stateLeast;
      }
    }

    return this.responsesHaveValues(values) && valuesAreDifferent;
  };

  MostLeastSurveyQuestion.prototype.renderOptions = function (optionId, label) {
    var _a = this.state,
        most = _a.most,
        least = _a.least;
    var isMostSelected = optionId === most;
    var isLeastSelected = optionId === least;
    return react_1.default.createElement(styled_1.ButtonContainer, {
      key: optionId
    }, react_1.default.createElement(styled_1.StyledButton, {
      onPress: this.pressMostHandler(optionId),
      disabled: isMostSelected
    }, isMostSelected && react_1.default.createElement(styled_1.SelectedOption, null)), react_1.default.createElement(styled_1.Answers, null, react_1.default.createElement(content_parser_1.ParsedContent, {
      content: label
    })), react_1.default.createElement(styled_1.StyledButton, {
      onPress: this.pressLeastHandler(optionId),
      disabled: isLeastSelected
    }, isLeastSelected && react_1.default.createElement(styled_1.SelectedOption, null)));
  };

  MostLeastSurveyQuestion.prototype.render = function () {
    var _this = this;

    var _a = this.props.question,
        label = _a.question,
        options = _a.options;
    var _b = this.props,
        questionNumber = _b.questionNumber,
        disabled = _b.disabled;
    return react_1.default.createElement(styled_1.QuestionContainer, {
      disabled: disabled
    }, react_1.default.createElement(styled_1.TextTitle, null, react_1.default.createElement(styled_1.QuestionNumberLabel, null, questionNumber, ".\xA0"), react_1.default.createElement(content_parser_1.ParsedContent, {
      content: label
    })), react_1.default.createElement(react_1.Fragment, null, react_1.default.createElement(styled_1.TextWrap, null, react_1.default.createElement(styled_1.OptionsText, null, "MOST"), react_1.default.createElement(styled_1.OptionsText, null, "LEAST")), options.map(function (_a) {
      var optionId = _a.optionId,
          option = _a.option;
      return _this.renderOptions(optionId, option);
    })));
  };

  return MostLeastSurveyQuestion;
}(react_1.Component);

exports.MostLeastSurveyQuestion = MostLeastSurveyQuestion;