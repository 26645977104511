"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});

var reselect_1 = require("reselect");

var getRegisterUser = function getRegisterUser(state) {
  return state.registerUser;
};

exports.registerUserSelector = reselect_1.createSelector(getRegisterUser, function (userRegistration) {
  return userRegistration;
});

var getEmailValidation = function getEmailValidation(state) {
  return state.authentication.registerUser.emailValidation;
};

exports.emailValidationSelector = reselect_1.createSelector(getEmailValidation, function (emailValidation) {
  return emailValidation;
});

var getPhoneValidation = function getPhoneValidation(state) {
  return state.authentication.registerUser.phoneValidation;
};

exports.phoneValidationSelector = reselect_1.createSelector(getPhoneValidation, function (phoneValidation) {
  return phoneValidation;
});

var getAuthState = function getAuthState(state) {
  return state.authentication;
};

exports.authUpdatingSelector = reselect_1.createSelector(getAuthState, function (authState) {
  return authState.view.updating;
});
exports.authPasswordResetModelSelector = reselect_1.createSelector(getAuthState, function (authState) {
  return authState.view.passwordReset;
});
exports.authPasswordResetUsernameSelector = reselect_1.createSelector(getAuthState, function (authState) {
  return authState.view.passwordReset.username;
});